import React from 'react';
import styles from './styles.module.css';
import { getLocalString } from '../../languages';
export function PrivacyLink () {
  return(
    <div className={styles['footerStyle']}>
      <span>© Zinier Inc. </span>
      <a
        href={getLocalString('terms_and_conditions_link',
          'https://www.zinier.com/terms-conditions/')}
        target="_blank"
        rel="noopener noreferrer">
        {`• ${getLocalString('terms_and_conditions',
          'Terms & Conditions')}`} </a>
      <a
        href={getLocalString('privacy_policy_link',
          'https://www.zinier.com/privacy-policy-app')}
        target="_blank"
        rel="noopener noreferrer">
        {`• ${getLocalString('privacy_policy',
          'Privacy Policy')}`} </a>
    </div>);
}


