/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';

import { Auth0Provider } from '../AthuO/AuthOwrapper';
import { AppContextData } from '../AppContext';

function OAuthLandingProxy (props) {
  const { state } = useContext(AppContextData);
  const language = require('locale2') || 'en-us';
  const [authOConfig, setAuthOConfig] = useState(null);
  useEffect(() => {
    setAuthOConfig(state);
  }, []);

  return (
    authOConfig ? <Auth0Provider
      language={language}
      state={props.location.state}
      domain={''}
      authType={authOConfig.authTypeResponse}
      client_id={authOConfig.authTypeResponse.clientId}
      redirect_uri={authOConfig.authTypeResponse.redirectUri}
    /> : null
  );
}

export const OAuthLanding = OAuthLandingProxy;
