/*  */
import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.css';
import { FIRST_INDEX } from '../../utilities/constants';

// The options array expects each element to be an Array
// Where element[0] represents the value of <option>
// and, element[1] represents the text to show in <option>
const OPTION_DATA_VALUE_INDEX = 0;
const OPTION_DATA_TEXT_INDEX = 1;


export const Select = (props) => {
  const {
    onChange,
    optionsData,
    value,
    prompt,
    className,
    validator,
    disabled,
    id,
    onBlur
  } = props;
  const selectStyle = classnames(
    'form-control',
    styles['select'],
    className,
    { [styles.incorrectInput]: validator }
  );
  return (
    <div className={styles.selectWrapper}>
      <select
        id={id}
        className={selectStyle}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
      >
        {
          prompt && prompt.length > FIRST_INDEX
            ?
            <option
              key={-1}
              value={''}
            >
              {prompt}
            </option>
            :
            null
        }
        {optionsData.map((optionData, index) => {
          return (
            <option
              key={index}
              value={optionData[OPTION_DATA_VALUE_INDEX]}
            >
              {optionData[OPTION_DATA_TEXT_INDEX]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
