//
import * as React from 'react';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { TextField } from '../../components/TextField';
import { getLocalString } from '../../languages';
import { TYPES } from '../../utilities/constants';
import styles from './styles.module.css';


export function OrgId (props) {



  return (
    <FieldWithLabel
      field={
        <>
          <TextField
            onChange={() => true}
            placeholder={getLocalString('enter_org_name_placeholder')}
            value={props.orgId}
            type={TYPES.TEXT}
            maxLength = {props.maxLength}
            readOnly={true}
          />
          { props.errorText &&
            <div className={styles.errorText}>
              {props.errorText}
            </div>
          }
        </>
      }
      label={getLocalString('org_name_label')}
    />
  );

}
