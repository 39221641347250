//
import * as React from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';


export class FieldWithLabel extends React.Component {
  render () {
    return (
      <div
        className={classnames(styles['formControl'], this.props.className)}
        style={this.props.style}
      >
        <div className={styles['label']}>
          {this.props.label}
        </div>
        {this.props.field}
      </div>
    );
  }
}
