/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useState } from 'react';
import { getLocalString } from '../../languages';
import styles from './style.module.css';
import { Loader } from '../../components/Loader/Loader';

function Tabs () {
  const [activeTab, setActiveTab] = useState('tab1');
  const [isLoading, setIsLoading] = useState(true);
  const termsIframe = 'https://www.zinier.com/zterms';
  const privacyIframe = 'https://www.zinier.com/zprivacy';

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setIsLoading(true);
    }
  };
  const hideSpinner = () => {
    setIsLoading(false);
  };

  return (
    <div className={styles.tabs}>
      <ul className={styles.nav}>
        <li
          className={activeTab === 'tab1' ? [styles['active']] : [styles['inactive']]}
          onClick={() => {
            toggle('tab1');
          }}
        >
          {getLocalString('terms_and_conditions')}
        </li>
        <li
          className={activeTab === 'tab2' ? [styles['active']] : [styles['inactive']]}
          onClick={() => {
            toggle('tab2');
          }}
        >
          {getLocalString('privacy_policy')}
        </li>
      </ul>
      { isLoading &&
        <Loader type='fullScreen'/>
      }
      <div className={styles.outlet}>
        {activeTab === 'tab1' ? (
          <div className={styles.FirstTab}>
            <iframe onLoad={hideSpinner} className={styles.terms} src={termsIframe}/>
          </div>
        ) : (
          <div className={styles.SecondTab}>
            { isLoading &&
              <Loader type='fullScreen'/>
            }
            <iframe onLoad={hideSpinner} className={styles.terms} src={privacyIframe}/>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tabs;
