//

import React from 'react';

import styles from './styles.module.css';
import classnames from 'classnames';
import { Text } from '../Text';
import { ReadOnly } from '../ReadOnly/Readonly';
import { ONE } from '../../utilities/constants';

/**
 * TextField
 */
export function TextField (props) {
  const {
    value,
    onClick,
    onChange,
    type,
    placeholder,
    onFocus,
    maxLength,
    minLength,
    showEyeIcon,
    helperText,
    errorText,
    errorClassName,
    src,
    incrementRange,
    autocomplete,
    ...rest
  } = props;
  return (
    <div>
      {props.readOnly ? (
        <ReadOnly id={props.id} value={value || ''} />
      ) : (
        <div className={showEyeIcon && styles.pwdContainer}>
          <input
            className={classnames(styles.textField, 'form-control', {
              [styles.incorrectInput]: props.isinvalid })}
            {...rest}
            type={type}
            autoComplete={autocomplete}
            onChange={onChange}
            onFocus={onFocus}
            value={value}
            min={minLength || ONE}
            max={maxLength}
            minLength={minLength}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={props.disabled}
            onBlur={props.onBlur}
            step={incrementRange}
          />
          {showEyeIcon && (
            <img src={src} onClick={onClick} className={styles.eyeImage} />
          )}
        </div>
      )}
      {errorText ? (
        <Text className={errorClassName}>{errorText}</Text>
      ) : (
        <Text className={styles.helperText}>{helperText}</Text>
      )}
    </div>
  );
}