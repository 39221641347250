/* eslint max-statements: 0 */
/* eslint-disable max-len */
import * as React from 'react';
import { queryString } from '../../utilities/queryString';
import { forEach, isEmpty } from 'lodash';
import { Text } from '../../components/Text';
import { ResetPasswordWrapper } from './ResetPassword';
import { ValidateSecurityQuestions } from './ValidateSecurityAnswers';
import { FOUR, getLocaleHeaders, ONE, prepareLabURL, ZERO } from '../../utilities/constants';
import { AppContextData } from './../AppContext/index';
import { getOrgDetailsForFivePartURL, getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';
import axios from 'axios';


/**
 * CreatePasswordContainer
 * A container acts as an interface to Application State
 * Since contained components cannot get access to State
 * They rely on their container to pass this information to them
 */
class ResetPasswordProxy extends
  React.Component {
  static contextType = AppContextData ;

  constructor (props) {
    super(props);
    this.state = {
      orgId: '',
      passwordToken: '',
      loginId: '',
      Questions: [],
      questionsAnswered: false,
      envName: '',
      envLab: ''
    };
  }

  /**
   * componentDidMount
  */
  componentDidMount = () => {
    this.fetchAnsweredQuestions();
    // const url = this.props.location.pathname+this.props.location.search;
    // localStorage.set('REDIRECT_TO_AFTER_SESSION_DESTROY', url);
    // // if (this.props.session) {
    // //   this.props.dispatch(new DestroySession(null).plainAction());
    // // }
  };

  apiCall = async () => {
    let envName, envLab;
    const url = window.location.hostname;
    const splitUrl = url.split('.');
    const queryStringParsed = queryString.parse(this.props.location.search);
    const updater = {};
    forEach(['orgId', 'passwordToken', 'loginId', 'env'], (x) => {
      updater[x] = queryStringParsed[x];
    });
    if(isEmpty(updater.env) || updater.env === 'undefined') {
      const result = await this.fetchEnvAndLab(splitUrl, updater.orgId);
      if(result && result.data) {
        envName = result.data.envName;
        envLab = result.data.envUrl;
        this.setState({ ...updater, envName, envLab });
      }
    } else {
      envName = updater.env;
      this.setState({ ...updater, envName });
    }
    return axios({
      url: `${prepareLabURL(envName, envLab)}/user/getAnsweredSecurityQuestions`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'orgId': queryStringParsed.orgId,
        'loginId': queryStringParsed.loginId,
        'token': queryStringParsed.passwordToken,
        ...getLocaleHeaders()
      },
      data: {}
    });
  };

  async fetchEnvAndLab (splitUrl, orgId) {
    if (splitUrl.length === FOUR) {
      const result = await getOrgDetailsForFivePartURL(orgId, splitUrl[ONE]);
      return result;
    }
    const result = await getOrgDetailsForThreePartURL(orgId);
    return result;
  }

  fetchAnsweredQuestions = async () => {
    let Questions = [];
    const response = await this.apiCall();
    if(response && response.data && response.data.data) {
      Questions = response.data.data;
      this.setState({ Questions: Questions });
    }
  };

  onQuestionsAnswered = () => {
    this.setState({ questionsAnswered: true });
  }

  /**
   * render
   * @return {*}
   */
  render () {
    return (
      <React.Fragment>
        {
          this.state.envName && this.state.loginId !== ''
            ?
            !this.state.questionsAnswered &&
            this.state.Questions &&
            this.state.Questions.length >ZERO ?
              <ValidateSecurityQuestions
                loginId={this.state.loginId}
                envId={this.state.envName}
                envLab={this.state.envLab}
                passwordToken={this.state.passwordToken}
                orgId={this.state.orgId}
                Questions = {this.state.Questions}
                onAnswer={this.onQuestionsAnswered}
              />:
              <ResetPasswordWrapper
                envId={this.state.envName}
                envLab={this.state.envLab}
                orgId={this.state.orgId}
                loginId={this.state.loginId}
                passwordToken={this.state.passwordToken}
                questionsAnswered={this.state.questionsAnswered}
              />
            :
            <Text>Loading...</Text>
        }
      </React.Fragment>
    );
  }
}

export const ResetPassword = ResetPasswordProxy;
