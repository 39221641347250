/*  */
import * as React from 'react';
import {
  Link as RouterLink
} from 'react-router-dom';
import classnames from 'classnames';
import styles from './styles.module.css';


export const Link = (props) => {
  const { to, isUnStyled, children, className } = props;
  return (
    <RouterLink
      className={classnames(styles['link'], className, {
        [styles['unStyled']]: isUnStyled
      })}
      to={to}
    >
      {children}
    </RouterLink>
  );
};

Link.defaultProps = {
  isUnStyled: false
};
