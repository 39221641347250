/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.module.css';
import { Container } from '../../components/Container/index';
import logo from '../Logo/zinier-logo-with-name-black.svg';
import { Button as ButtonComponent } from '../../components/Button/index';
import { forEach, isEmpty } from
  'lodash';
import { FieldWithLabel } from
  '../../components/FieldWithLabel/FieldWithLabel';
import { TextField } from '../../components/TextField/index';
import { AlertControlled } from
  '../../components/AlertControlled/AlertControlled';
import { getLocaleHeaders, NEGATIVE_ONE, ONE, ZERO } from '../../utilities/constants';
import { queryString } from '../../utilities/queryString';
import axios from 'axios';
// import { localStorage } from '../../services/LocalStorage/localStorage';
import { activeQuesQuery } from './ConstantField';
import { Select } from '../../components/Select';
import { NavLinkV2 } from '../../components/NavLinkV2';
import { Link } from '../../components/Link';
import { getLocalString } from '../../languages/index';
import { hasCrossScript } from '../../utilities/sanitizer';
import stylesFromSignIn from '../OrgLanding/styles.module.css';
import { handleCookies, handleRedirect } from '../../handleCookies';
import { AppContextData } from '../AppContext';
import { PrivacyLink } from '../../components/PrivacyLink';


class SetSecurityQuestionsProxy extends Component {
  static contextType = AppContextData;

  state = {
    orgId: '',
    token: '',
    loginId: '',
    numOfQues: ZERO,
    Questions: [],
    errors: null,
    successMessage: null,
    userData: [],
    isButtonDisable: false,
    mobileAccess: false
  };

  componentDidMount () {
    const queryStringParsed = queryString.parse(this.props.location.search);
    if(queryStringParsed) {
      const updater = {};
      forEach(['orgId', 'token',
        'loginId', 'numOfQues', 'mobileAccess', 'envUrl', 'env', 'isUserActivation'], (x) => {
        updater[x] = queryStringParsed[x];
      });
      updater['isUserActivation'] = JSON.parse(updater['isUserActivation']);
      updater['mobileAccess'] = JSON.parse(updater['mobileAccess']);
      this.setState(updater, () => this.fetchQuestions());
    }

    /**
     * On the first load there will be no route for forward.
     * So user can set the security question.
     * Once user successfully setup the security questions then system will
     * redirect the user to next page(Mobile Unlock page).
     * From the next page if user clicks back button securityQuestion page
     * will redirect the user to the page where he/she clicked the back button.
     *
     */
    this.props.history.goForward();

  }

  fetchQuestions = async () => {
    try {
      const response = await axios({
        url: `${this.state.envUrl}/query/read`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'orgId': this.state.orgId,
          'loginId': this.state.loginId,
          'token': this.state.token,
          ...getLocaleHeaders()
        },
        data: { data: activeQuesQuery }
      });
      if (response && response.data.data) {
        ((data, numOfQues) => {
          const ques = [];
          const saveData = [];
          data.forEach(itr => ques.push([itr.id, itr.name]));
          const numberOfQues = numOfQues > ques.length ?
            ques.length : numOfQues;
          for (let i = 0; i < numberOfQues; i++) {
            saveData[i] = { id: '', ans: '' };
          }
          this.setState({ Questions: ques, userData: saveData });
        })(response.data.data, this.state.numOfQues);
      }
    }catch (err) {
      this.setState({
        errors: (() => {
          try {
            return err.response.data.errorMessage;
          } catch (e) {
            return '';
          }
        })()
      });
    }
  };

  checkForCrossScript = () => {
    const isCrossScripted = hasCrossScript(this.state.userData);
    if(isCrossScripted) {
      this.setState({ errors: getLocalString('something_went_wrong') });
      return;
    }
    this.handleSubmit();
  }

  handleSubmit = async () => {
    const contextData = this.context.state;
    this.setState({ isButtonDisable: true, errors: null });
    try {
      const response = await axios({
        url: `${this.state.envUrl}/user/saveSecurityAns`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'orgId': this.state.orgId,
          'loginId': this.state.loginId,
          'token': this.state.token,
          ...getLocaleHeaders()
        },
        data: { data: this.state.userData }
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('user_activation_success_text');
        }
      })();
      if(!(this.state.isUserActivation)) {
        handleCookies(contextData.session, contextData.env_id, contextData.env_lab, contextData.authTypeResponse);
        handleRedirect(contextData.session, contextData);
      }
      this.setState({
        successMessage: message
      });
    }catch (err) {
      this.setState({
        isButtonDisable: false,
        errors: (() => {
          try {
            return err.response.data.errorMessage;
          } catch (e) {
            return '';
          }
        })()
      });
    }
  };

  buttonEnable = () => {
    const stateInd = this.state.userData && this.state.userData.map(item => {
      return !isEmpty(item.id) && !isEmpty(item.ans);
    });
    return stateInd.includes(false) || this.state.isButtonDisable;
  };

  onchangeAns = (i, e) => {
    const changedState = {
      id: this.state.userData[i].id,
      ans: e.target.value
    };
    const data = (( changedStateData, onchangeData) => {
      if ( onchangeData.length === ZERO) {
        return [changedStateData];
      }
      const index =
          onchangeData.map(itr => itr.id).indexOf(changedStateData.id);
      if(index !== NEGATIVE_ONE &&
          onchangeData[index].id === changedStateData.id) {
        onchangeData[index].ans = changedStateData.ans;
        return onchangeData;
      }
      return [...onchangeData, changedStateData];
    })(changedState, this.state.userData);
    this.setState({ userData: data });
  };

  onQuesChange = (i, e) => {
    const data = this.state.userData;
    data[i].id = e.target.value;
    data[i].ans = '';
    this.setState({ userData: data });
  };

  selectOptions (id) {
    const selectedQuestion = this.state.userData.filter((itr) => itr.id !== id);
    return this.state.Questions
      .filter(filter => !selectedQuestion
        .map(item => item.id)
        .includes(filter[ZERO]));
  }

  render () {
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <img
            src={logo}
            alt={'zinier'}
            style={{
              marginBottom: '2rem'
            }}
          />
          <div className={classnames(styles.card, 'col-md-4')}>
            <p className={styles.cardTitle}>
              {getLocalString('select_Security_Questions')}</p>
            <div className={styles.borderTextBottomWrapper}>
              <div className={styles.borderTextBottom}/>
            </div>
            <div className={styles.SecQuesDescription}>
              <p>{getLocalString('Select_Security_Questions_Desc')}</p>
            </div>
            {
              !isEmpty(this.state.errors) &&
                  <AlertControlled
                    message={this.state.errors}
                    onClose={
                      (() => {
                        this.setState({ errors: null });
                      })}
                    type={'danger'}
                    style={{ marginBottom: '1rem' }}
                  />
            }
            { this.state.successMessage ?
              <AlertControlled
                className={styles['alert']}
                type={'success'}
                message={this.state.successMessage}
                timeout={2000}
                onClose={
                  (() => {
                    this.setState({ successMessage: null }, () => {
                      if(this.state.mobileAccess) {
                        window.location.href = '/mobileUnlock?orgId='+
                          this.state.orgId +'&env='+this.state.env+
                        '&loginId=' + encodeURIComponent(this.state.loginId);
                      } else {
                        window.location.href = '/?org='
                      +this.state.orgId+'&env='+this.state.env+'&loginId='+
                          encodeURIComponent(this.state.loginId);
                      }
                    });
                  })
                }
              />: null
            }
            {
              this.state.userData && this.state.userData.length> ZERO &&
              this.state.userData.map((ques, i) => <div key={i}>
                <FieldWithLabel
                  field={
                    <div>
                      <Select
                        onChange={this.onQuesChange.bind(this, i)}
                        optionsData={this.selectOptions(ques.id)}
                        value={ques.id}
                        prompt={getLocalString('Select_Question')}
                        className={styles['fieldBottom']}
                      />
                      <TextField
                        placeholder={getLocalString('Enter_your_answer')}
                        type={'text'}
                        disabled={isEmpty(ques.id)}
                        value={ques.ans}
                        onChange={this.onchangeAns.bind(this, i)}
                      />
                    </div>
                  }
                  className={styles['topBuffer']}
                  label={getLocalString('Question')+' '+(i+ONE)}/>
              </div>
              )
            }
            <div className={styles.topBuffer}>
              <ButtonComponent
                className={styles.fullWidth}
                type={'primary'}
                disabled={this.buttonEnable()}
                onClick={this.checkForCrossScript}
              >
                {getLocalString('submit_button')}
              </ButtonComponent>
            </div>
          </div>
          <div className={
            classnames(stylesFromSignIn['redirectOrdId'], 'col-md-4')}>
            <p>{getLocalString('pf_already_have_account')}
              <NavLinkV2
                classname={stylesFromSignIn.linkWrapper}
                isUnstyled={true}
                onClick={() => true }>
                <Link
                  isUnStyled={false}
                  to={ '/'}
                  className={stylesFromSignIn.orgidLink}
                >
                  {getLocalString('pf_here_link')}
                </Link></NavLinkV2>
            </p>
          </div>
          {PrivacyLink()}
        </div>
      </Container>
    );
  }
}

export const SetSecurityQuestions = SetSecurityQuestionsProxy;
