import strings from './strings.json';
import LocalisedStrings from 'react-localization';
import { toLower } from 'lodash';

/**
 * all bundled languages
 * @type {{}}
 */
const allLanguages = {
  ...strings
};
export const language = new LocalisedStrings(allLanguages);
language.setLanguage(toLower(require('locale2') || 'en-us'));



// This function can be used for the strings that are controlled by platform
export function getLocalString (inputStr, fallback) {
  if(language[inputStr]) {
    return language[inputStr];
  }
  if(fallback) {
    return fallback;
  }
  return null;
}
