import React, { useContext } from 'react';
import {
  TextField
} from '../../components/TextField';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';

function CodeField () {
  const { dispatch } = useContext(AppContextData);

  const handleChange = (e) => {
    const code = e.target.value;
    dispatch({
      type: '2FA_CODE_CHANGE',
      payload: code
    });

  };


  return (
    <FieldWithLabel
      style={{
        marginBottom: '1rem'
      }}
      field={
        <TextField
          type="text"
          placeholder={getLocalString('enter_code')}
          onChange={handleChange}
        />
      }
      label={getLocalString('verification_code')}
    />
  );

}

export const AuthCode = CodeField;
