/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useState } from 'react';
import { sanitizeInput } from '../../utilities/sanitizer';
import { getLocalString } from '../../languages';
import { Button as ButtonComponent } from '../../components/Button/index';
import { Loader } from '../../components/Loader/Loader';
import styles from './styleNew.module.css';
import MobileHomeScreen from '../assests/mobileHomeScreen.svg';
import AccountActivatedIcon from '../assests/AccountActivated.svg';
import { getOrgDetails } from '../../utilities/getOrgEnvDetails';
import { useContext } from 'react';
import { AppContextData } from '../AppContext';
const AccountActivated = (props) => {
  const { dispatch } = useContext(AppContextData);

  const [isLoading, setIsLoading] = useState(true);
  const androidPlayStoreLink = 'https://play.google.com/store/apps/details?id=com.zinier.app&hl=en_US';
  const androidPlayStoreIcon = 'https://d122pusslfmuhu.cloudfront.net/mail/playstore.png';
  const iosAppStoreLink = 'https://itunes.apple.com/in/app/zinier/id1043749020?mt=8';
  const iosAppStoreIcon = 'https://d122pusslfmuhu.cloudfront.net/mail/appstore.png';

  const handleSubmit = async () => {
    const { data: { orgIdAlias } } = await getOrgDetails(props.orgId, props.env);
    const webAccountActivatedLink = '/?org=' + orgIdAlias + '&loginId=' + encodeURIComponent(props.loginId) + '&env=' + props.env;

    dispatch({
      type: 'LOGIN_ID',
      payload: props.loginId
    });

    window.location.href = webAccountActivatedLink;
  };

  const handleAppSubmit = async () => {
    const { data: { orgIdAlias } } = await getOrgDetails(props.orgId, props.env);
    const appDeepLink = `https://app.zinier.com/deep-link?org=${orgIdAlias}&url=${props.envUrl}&env=${props.env}&aliasOrgName=${orgIdAlias}&loginId=${sanitizeInput(props.loginId)}`;
    window.location.href = appDeepLink;
  };

  const onLoad = () => {
    setIsLoading(false);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['mobile', 'android', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];

    return mobileKeywords.some(keyword => userAgent.includes(keyword));
  };

  return (
    <>
      { isLoading &&
        <Loader type='fullScreen'/>
      }
      <div className={styles.checkmark}>
        <img src={AccountActivatedIcon} onLoad={onLoad} />
      </div>
      <div className={styles.activatedContent}>
        <div className={styles.messageContent}>
          <b>{(getLocalString('pf_account_activated_acknowledgement'))}</b>
        </div>
        <div className={styles.topBuffer}>
          <ButtonComponent
            className = {styles.webButton}
            type='primary'
            onClick = {handleSubmit}
          >
            {(getLocalString('web_button'))}
          </ButtonComponent>
          {isMobileDevice() && <ButtonComponent
            className = {styles.appButton}
            type='primary'
            onClick = {handleAppSubmit}
          >
            {(getLocalString('app_button'))}
          </ButtonComponent>
          }
        </div>
        <div className={styles.Content}>
          <img src={MobileHomeScreen}
            style={{ width: '15%', height: 'auto' }} alt="Mobile Home Screen" />
          <div className={styles.innerContent}>
            <b>{(getLocalString('pf_unlock_connectivity'))}</b>
            <p>{(getLocalString('pf_app_installation'))}</p>
          </div>
        </div>
        {<div className={styles.storeContent}>
          <a className= {styles.storeLink}
            href={iosAppStoreLink}
          >
            <img className= {styles.storeImage}
              src={iosAppStoreIcon}
              alt='Appstore'
            />
          </a>
          <a className= {styles.storeLink}
            href={androidPlayStoreLink}
          >
            <img className= {styles.storeImage}
              src={androidPlayStoreIcon}
              alt='Playstore'
            />
          </a>
        </div>}
      </div>
    </>
  );
};

export default AccountActivated;
