import React, { useState, useEffect, useContext } from 'react';
import {
  Button as ButtonComponent
} from '../../components/Button';
import classnames from 'classnames';
import styles from './styles.module.css';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import { Radio } from '../../components/Radio';
import { Text } from '../../components/Text';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';
import { PrivacyLink } from '../../components/PrivacyLink';

function AuthOptionsProxy (props) {
  const { dispatch, state } = useContext(AppContextData);
  const [selected, setSelected] =useState('none');
  const [authOptionsArray, setAuthOptionsArray] = useState([]);




  useEffect(() => {
    const options = state.session.authOptions;
    const authArray = [];
    for (const jsonKey in options) {
      if(options.hasOwnProperty(jsonKey)) {
        const authObj = {};
        authObj['type'] = jsonKey;
        authObj['value'] = options[jsonKey];
        authArray.push(authObj);
      }
    }
    setSelected(props.otpMode);
    setAuthOptionsArray(authArray);
  }, []);



  const setOption = (event, value) => {
    setSelected(value);
  };

  const getRadioOptios = () => {
    const options = authOptionsArray;
    const radioMenu = [];
    for(let i = 0; i < options.length; i++) {
      const authObj = options[i];
      radioMenu.push(
        <label key={i} className={styles.authOptionsBox}>
          <Radio
            onChange={(event) => setOption(event, authObj['type'])}
            checked={selected === authObj['type']}
          /> {authObj['type'].toUpperCase()} to {authObj['value']}
        </label>
      );
    }
    return (
      <div className={styles.authOptionsContent}>
        {radioMenu}
      </div>
    );
  };

  const handleClick = () => {
    props.onResendClick(selected);
    dispatch({
      type: 'CHANGE_AUTH_MENU',
      payload: true
    });
  };


  return (
    <div className={classnames(styles['Wrapper'], 'row')} >
      {/* {this.state.isWorking ?
            <Loader type='fullScreen'/> : null
          } */}
      <img
        src={logo}
        alt={'zinier'}
        style={{
          marginBottom: '2rem'
        }}
      />
      <div className={
        classnames(styles['Card'], 'col-md-4')}
      >
        <div className={styles['text']}>
          {getLocalString('try_another_way_to_login')}
        </div>
        <div className={styles['borderTextBottomWrapper']}>
          <div className={styles['borderTextBottom']}/>
        </div>
        <div className={styles['authOptionsWrapper']}>
          <div>{getLocalString('choose_one_authentication_methods')}</div>
        </div>
        <Text type="label">{getLocalString('authentication_methods')}</Text>
        {getRadioOptios()}
        <ButtonComponent
          type={'primary'}
          onClick={handleClick}
          disabled={selected === 'none'}
          className={classnames(styles['buttonStyle'],
            styles['twoStepButton'])}
        >
          {getLocalString('Save')}
        </ButtonComponent>
      </div>
      {PrivacyLink()}
    </div>
  );

}





export const AuthOptions = AuthOptionsProxy;
