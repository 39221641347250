import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import { log } from '../../utilities/logger';
import useUrlParser from './useUrlParser';
import { getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';
export const validateActivationStatus = BaseComponent => props => {
  const [dataFromURL, setDataFromURL] = useState({});
  useEffect(async () => {
    // Check if user is already activated,
    // if true redirect to login else take to activation
    try {
      const updater = await useUrlParser();
      const { data: { orgId, orgIdAlias } } =
        await getOrgDetailsForThreePartURL(updater.orgId, updater.env);
      updater.orgId = orgId;
      updater.orgIdAlias = orgIdAlias;
      const response = await axios({
        url: `${updater.lab}/user/getActivationStatus`,
        headers: pick(updater, [
          'orgId',
          'passwordToken',
          'loginId'
        ]),
        'Content-Type': 'application/json',
        method: 'POST',
        data: {}
      });

      if (response?.data['userActivationStatus']) {
        window.location.href = `${window.location.origin}?` +
          `org=${orgIdAlias}&env=${updater.env}&lab=${updater.lab}&`+
          `user=${encodeURIComponent(updater.loginId)}`;
      } else {
        setDataFromURL(updater);
      }
    } catch (err) {
      log(err);
    }
  }, []);
  return (<BaseComponent {...props} dataFromURL={dataFromURL}/>);
};
