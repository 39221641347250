/* eslint-disable max-len */
/* eslint max-statements: 0 */
import axios from 'axios';
import { activeQuesQuery, numofQuesQuery } from '../features/UserActivation-Contained/ConstantField';
import { isEmpty } from 'lodash';
import { getLocaleHeaders, prepareLabURL, ZERO } from './constants';

const getLabURL = ({ env_id, env_lab }) => prepareLabURL(env_id, env_lab);

export const triggerSecQuesCall = async (data, stateData, isUserActivation) => {
  try {
    const envLab = isUserActivation ? stateData.envUrl : getLabURL(stateData);
    const env = isUserActivation ? stateData.envId : stateData.env_id;
    const mobileAccess = isUserActivation ? data['mobileAccess'] : false;
    const response = await axios({
      url: `${envLab}/query/read`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'orgId': data.orgId,
        'loginId': data.loginId,
        'token': data['token'],
        ...getLocaleHeaders()
      },
      data: { data: numofQuesQuery }
    });
    const urlOrgId = data.orgIdAlias || data.orgId;
    const isActiveQuestions = await queryActiveQuestions(data, envLab);
    if(response && response.data && !isEmpty(response.data.data) &&
            response.data.data[ZERO].configValue > ZERO && isActiveQuestions) {
      window.location.href = '/setSecurityQues?orgId='
                +urlOrgId+'&loginId='+
                encodeURIComponent(data.loginId)+
                '&token='+data['token']+'&envUrl='+envLab+'&mobileAccess='+mobileAccess+
                '&numOfQues='+response.data.data[ZERO].configValue+'&env='+env+'&isUserActivation='+isUserActivation;
    }else if (response && isEmpty(response.data.data) && mobileAccess && isUserActivation) {
      window.location.href = '/mobileUnlock?orgId='+ urlOrgId
                +'&loginId=' + encodeURIComponent(data.loginId)+'&env='+env;
    }else {
      window.location.href = '/?org='+ urlOrgId +'&env='+env+
                '&loginId=' + encodeURIComponent(data.loginId);
    }
    return true;
  }catch (err) {
    return false;
  }
};

const queryActiveQuestions = async (data, envLab) => {
  try {
    const response = await axios({
      url: `${envLab}/query/read`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'orgId': data.orgId,
        'loginId': data.loginId,
        'token': data['token'],
        ...getLocaleHeaders()
      },
      data: { data: activeQuesQuery }
    });
    return Boolean(response && response.data.data && response.data.data.length > ZERO);
  }catch (e) {
    return false;
  }
};
