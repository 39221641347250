/* eslint-disable no-unused-vars */
/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container';
import classnames from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import styles from './styles.module.css';
import OrgIdField from './orgID';
import EnvIdField from './environmentID';
import { ButtonBase } from './orgValidation';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';
import { useLoginApi } from '../../apiHooks/apiHooks';
import { OAUTH_TYPE } from '../../utilities/constants';
import { useHistory } from 'react-router-dom';
import { queryString as test } from '../../utilities/queryString';
import { areCookiesAvailable } from '../../handleCookies';
import { validateSession } from '../ValidateRoute/validateSession';
import { PrivacyLink } from '../../components/PrivacyLink';
import packageJSON from '../../../package.json';

function OrgLandingProxy () {
  const history = useHistory();
  const queryString = window.location.search;
  let orgFromParams, envFromParams, envLabFromParams, appFromParams, redirectURL, userFromParams;
  const { handleAuthTypeApi } = useLoginApi();
  if (queryString) {
    const params = new URLSearchParams(queryString);
    orgFromParams = params.get('orgId') || params.get('orgid') || params.get('org');
    envFromParams = params.get('env');
    appFromParams = params.get('app');
    redirectURL=params.get('redirect');
    envLabFromParams = params.get('lab');
    userFromParams = params.get('loginId') || params.get('loginid') || params.get('login') || params.get('user');
  }
  const { dispatch, state } = useContext(AppContextData);
  const [orgID, setOrgID] = useState(orgFromParams || state.org_id);
  const [envName, setEnvName]= useState(envFromParams || state.env_id);
  const [userName, setloginID]= useState(userFromParams||'');

  /**
   * If org and env is available both in state and params return true else false
   */
  const isOrgAndEnvAvailable = () => {
    return orgFromParams && envFromParams && state.org_id && state.env_id;
  };


  useEffect(() => {
    if(state.authTypeResponse && state.authTypeResponse.authType === OAUTH_TYPE) {
      if(window.location.search.includes('code=') &&
          window.location.search.includes('state=')) {
        const queryStringParsed = test.parse(window.location.search);

        history.push({
          pathname: '/auth-sign-in',
          state: {
            code: queryStringParsed.code,
            state: queryStringParsed.state
          }
        });
      } else {
        dispatch({
          type: 'AUTH_TYPE_SUCCESS',
          payload: {}
        });
      }
    }
  }, []);

  useEffect(() => {
    setOrgID(state.org_id);
    setEnvName(state.env_id);
    if(isOrgAndEnvAvailable() && !areCookiesAvailable()) {
      dispatchAuthType();
    }
    if(redirectURL) {
      dispatch({
        type: 'REDIRECT_URL',
        payload: redirectURL
      });
    }
    dispatch({
      type: 'APP_NAME',
      payload: appFromParams
    });
    if(envFromParams) {
      dispatch({
        type: 'ENV_ID',
        payload: envFromParams.toLowerCase()
      });
    }
    if(orgFromParams) {
      dispatch({
        type: 'ORG_ID',
        payload: orgFromParams
      });
    }
    if(envLabFromParams) {
      dispatch({
        type: 'ENV_LAB',
        payload: envLabFromParams
      });
    }
    if(userFromParams) {
      setloginID(userFromParams);
      dispatch({
        type: 'LOGIN_ID',
        payload: userFromParams
      });
    }
    if (packageJSON?.version) {
      dispatch({
        type: 'LOGIN_VERSION',
        payload: packageJSON.version
      });
    }
  }, [state.org_id, state.env_id]);

  const dispatchAuthType = () => {
    dispatch({
      type: 'SET_LOADER',
      payload: true
    });
    const initialState = { ...state };
    if(orgFromParams) {
      initialState.org_id= orgFromParams;
    }
    if(envFromParams) {
      initialState.env_id= envFromParams;
    }
    handleAuthTypeApi(initialState);
  };

  return (
    <Container>
      {isOrgAndEnvAvailable() || state.isLoading ? <Loader type='fullScreen'/> :
        <div className={classnames(styles['Wrapper'], 'row')} >
          <img
            src={logo}
            alt={'zinier'}
            style={{
              marginBottom: '2rem'
            }}
          />
          <div className={styles.Card+ ' ' + styles.OrgIdContainer}
          >
            <div className={styles['text']}>
              {getLocalString('pf_welcome_zinier')} </div>

            <div className={styles['borderTextBottomWrapper']}>
              <div className={styles['borderTextBottom']}/>
            </div>
            <EnvIdField isReadOnly={false} envName={envName}/>
            <OrgIdField isReadOnly={false} orgID={orgID}/>
            <ButtonBase/>
            <hr className={styles['hr']}/>

          </div>
          {PrivacyLink()}
        </div>
      }
    </Container>
  );
}


export const OrgLanding = validateSession(OrgLandingProxy);
