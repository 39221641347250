/*  */
import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.css';


export const Container = (props) => {
  const { className, children } = props;
  return (
    <div ref={props.containerRef} className={
      classnames('container', styles['container'], className)
    }>
      {children}
    </div>
  );
};
