//
import * as React from 'react';
import { Tooltip as CustomTooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './styles.css';

const ANIMATIONS = {
  fade: 'fade',
  shift: 'shift',
  scale: 'scale',
  perspective: 'perspective',
  none: 'none'
};

const POSITION = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  topEnd: 'top-end',
  topStart: 'top-start'
};

const TOOLTIP_SIZE = {
  small: 'small',
  regular: 'regular',
  big: 'big'
};

const ARROW_SIZE = {
  small: 'small',
  regular: 'regular',
  big: 'big'
};



// for more props, check this link - https://github.com/tvkhoa/react-tippy

export class Tooltip extends React.Component {
  static ANIMATIONS = ANIMATIONS;

  static POSITION = POSITION;

  static SIZE = TOOLTIP_SIZE;

  static ARROW_SIZE = ARROW_SIZE;

  render () {
    return(
      <CustomTooltip
        key={this.props.distance}
        distance= {this.props.distance}
        html={this.props.html}
        title={this.props.title}
        position={this.props.position}
        arrow={ this.props.arrow || 'true'}
        animation = {this.props.animation}
        size={ this.props.size || 'small'}
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
      >
        {this.props.children}
      </CustomTooltip>
    );
  }
}