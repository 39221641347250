/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import styles from '../OrgLanding/styles.module.css';
import { Container } from '../../components/Container';
import classnames from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import OrgIdField from '../OrgLanding/orgID';
import { NavLinkV2 } from '../../components/NavLinkV2';
import { Link } from '../../components/Link';
import LoginIdField from './loginId';
import PasswordField from './passwordField';
import { ButtonBaseLogin } from './submitButton';
import { AppContextData } from '../AppContext';
import { AlertControlled } from './../../components/AlertControlled/AlertControlled';
import isEmpty from 'lodash/isEmpty';
import { validateRoute } from './../ValidateRoute/validateRoute';
import { getLocalString } from '../../languages';
import { validateSession } from '../ValidateRoute/validateSession';
import { PrivacyLink } from '../../components/PrivacyLink';

function SignIn () {
  const { state, dispatch } = useContext(AppContextData);
  const errorMessage = state.error;

  useEffect(() => {
    dispatch({
      type: 'SET_ERROR',
      payload: ''
    });

    const queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const orgFromParams = params.get('orgId') || params.get('orgid') || params.get('org');
      const envFromParams = params.get('env');
      const appFromParams = params.get('app');
      const redirectURL=params.get('redirect');
      const envLabFromParams = params.get('lab');
      const userFromParams = params.get('loginId') || params.get('loginid') || params.get('login');

      if (orgFromParams) {
        dispatch({
          type: 'ORG_ID',
          payload: orgFromParams
        });
      }

      if (envFromParams) {
        dispatch({
          type: 'ENV_ID',
          payload: envFromParams
        });
      }

      if (appFromParams) {
        dispatch({
          type: 'APP_NAME',
          payload: appFromParams
        });
      }

      if (redirectURL) {
        dispatch({
          type: 'REDIRECT_URL',
          payload: redirectURL
        });
      }

      if (envLabFromParams) {
        dispatch({
          type: 'ENV_LAB',
          payload: envLabFromParams
        });
      }

      if (userFromParams) {
        dispatch({
          type: 'LOGIN_ID',
          payload: userFromParams
        });
      }
    }

    dispatch({
      type: 'SET_ERROR',
      payload: ''
    });
  }, []);

  const handleClose = () => {
    dispatch({
      type: 'SET_ERROR',
      payload: ''
    });
  };

  const resetRedirectURL = () => {
    dispatch({
      type: 'REDIRECT_URL',
      payload: ''
    });
    dispatch({
      type: 'ENV_LAB',
      payload: ''
    });
  };


  return (
    <Container>
      <div className={classnames(styles['Wrapper'], 'row')} >
        { state.isLoading ?
          <Loader type='fullScreen'/> : null
        }
        <img
          src={logo}
          alt={'zinier'}
          style={{
            marginBottom: '2rem'
          }}
        />
        <div className={
          classnames(styles['Card'], 'col-md-4')}
        >
          <div className={styles['text']}>
            {getLocalString('signin_heading')}</div>
          <div className={styles['borderTextBottomWrapper']}>
            <div className={styles['borderTextBottom']}/>
          </div>
          {!isEmpty(errorMessage) ? <AlertControlled
            message={errorMessage}
            onClose={handleClose}
            type={'danger'}
            style={{
              marginBottom: '1rem'
            }}/> : null}
          <OrgIdField isReadOnly={true} orgID={state.org_id}/>
          <LoginIdField />
          <PasswordField/>
          <ButtonBaseLogin/>
          <hr className={styles['hr']}/>
          <div className={styles.forgotPassLink}>
            <NavLinkV2
              isUnstyled={true}
              onClick={() => null}>
              <Link
                isUnStyled={true}
                to={ '/forgot-password'}
                className={styles.link}
              >
                {getLocalString('signin_forgot_password')}
              </Link>
            </NavLinkV2>
          </div>
        </div>
        <div className={
          classnames(styles['redirectOrdId'], 'col-md-4')}>
          <NavLinkV2
            classname={styles.linkWrapper}
            isUnstyled={true}
            onClick={() => resetRedirectURL()}>
            <Link
              isUnStyled={true}
              to={ '/'}
              className={styles.orgidLink}
            >
              {getLocalString('pf_login_into_different_org')}
            </Link></NavLinkV2>
        </div>
        {PrivacyLink()}
      </div>
    </Container>
  );
}

export const SignInProxy = validateSession(validateRoute(SignIn));
