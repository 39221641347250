/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useState, useEffect } from 'react';
import { ZERO, ONE, TWO, THREE, FOUR } from '../../utilities/constants';
import { Container } from '../../components/Container/index';
import { UserActivationContainerNew } from '../UserActivation-Contained/UserActivationContainerNew';
import { Footer } from '../Copyright/Footer';
// import SelectLanguage from '../PreferredLanguage/SelectLanguage';
import classnames from 'classnames';
import styles from '../UserActivation-Contained/styleNew.module.css';
import Stepper from '../StepperBuilder/Stepper';
import { SetSecurityQuestionsNew } from '../UserActivation-Contained/SetSecurityQuestionsNew';
import { TermsAndPrivacyContainer } from '../TermsAndPrivacy/TermsAndPrivacyContainer';
import AccountActivated from '../UserActivation-Contained/AccountActivated';

export function UserActivationFlow () {
  const defaultStep = ZERO;
  const getInitialStep= JSON.parse(sessionStorage.getItem('activeStep')) === THREE || JSON.parse(sessionStorage.getItem('activeStep')) === FOUR
    ? JSON.parse(sessionStorage.getItem('activeStep')) : defaultStep;
  const [activeStep, setActiveStep] = useState(getInitialStep);
  const [orgIdParam, setOrgIdParam] = useState(sessionStorage.getItem('orgId') ? JSON.parse(sessionStorage.getItem('orgId')) : null);
  const [loginIdParam, setLoginIdParam] = useState(sessionStorage.getItem('loginId') ? JSON.parse(sessionStorage.getItem('loginId')) : null);
  const [tokenParam, setTokenParam] = useState(sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null);
  const [envUrlParam, setEnvUrlParam] = useState(sessionStorage.getItem('envUrl') ? JSON.parse(sessionStorage.getItem('envUrl')) : null);
  const [numOfQuesParam, setNumOfQuesParam] = useState(sessionStorage.getItem('numOfQues') ? JSON.parse(sessionStorage.getItem('numOfQues')) : null);
  const [isUserActivationParam, setIsUserActivationParam] = useState(sessionStorage.getItem('isUserActivation') ? JSON.parse(sessionStorage.getItem('isUserActivation')) : null);
  const [envParam, setEnvParam] = useState(sessionStorage.getItem('env') ? JSON.parse(sessionStorage.getItem('env')) : null);
  const [passwordParam, setPasswordParam] = useState(null);
  const [passwordConfirmationParam, setPasswordConfirmationParam] = useState(null);

  useEffect(() => {
    if (passwordParam !== null && passwordConfirmationParam !== null && activeStep === ZERO) {
      handleNext();
    }
  }, [passwordParam, passwordConfirmationParam]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + ONE);
    sessionStorage.setItem('activeStep', activeStep + ONE);
  };

  const handleSkipSecQuestionStep = (orgId, loginId, env, envUrl) => {
    setActiveStep((prevActiveStep) => prevActiveStep + TWO);
    setOrgIdParam(orgId);
    setLoginIdParam(loginId);
    setEnvParam(env);
    setEnvUrlParam(envUrl);
    setSessionStorageDataSecQuesSkip(orgId, loginId, env, envUrl, activeStep);
  };

  function setSessionStorageData (orgId, loginId, token, env, envUrl, numOfQuesParam, isUserActivation) {
    sessionStorage.setItem('envUrl', JSON.stringify(envUrl));
    sessionStorage.setItem('orgId', JSON.stringify(orgId));
    sessionStorage.setItem('loginId', JSON.stringify(loginId));
    sessionStorage.setItem('token', JSON.stringify(token));
    sessionStorage.setItem('env', JSON.stringify(env));
    sessionStorage.setItem('numOfQues', JSON.stringify(numOfQuesParam));
    sessionStorage.setItem('isUserActivation', JSON.stringify(isUserActivation));
  }

  function setSessionStorageDataSecQuesSkip (orgId, loginId, env, envUrl, activeStep) {
    sessionStorage.setItem('envUrl', JSON.stringify(envUrl));
    sessionStorage.setItem('orgId', JSON.stringify(orgId));
    sessionStorage.setItem('loginId', JSON.stringify(loginId));
    sessionStorage.setItem('env', JSON.stringify(env));
    sessionStorage.setItem('activeStep', activeStep + TWO);
  }

  function setSecurityQuestionsData (
    orgId,
    loginId,
    token,
    env,
    envUrl,
    numOfQues,
    isUserActivation
  ) {
    setOrgIdParam(orgId);
    setLoginIdParam(loginId);
    setTokenParam(token);
    setEnvParam(env);
    setEnvUrlParam(envUrl);
    setNumOfQuesParam(numOfQues);
    setIsUserActivationParam(isUserActivation);
    setSessionStorageData(orgId, loginId, token, env, envUrl, numOfQues, isUserActivation);
  }

  function setPasswordData (password, passwordConfirmation) {
    setPasswordParam(password);
    setPasswordConfirmationParam(passwordConfirmation);
  }

  function getStepContent (stepIndex) {
    switch (stepIndex) {
    case ZERO:
      return (
        <UserActivationContainerNew
          handleNext={handleNext}
          setPasswordData={setPasswordData}
        />
      );
    case ONE:
      return (
        <TermsAndPrivacyContainer
          passwordParam={passwordParam}
          passwordConfirmationParam={passwordConfirmationParam}
          handleNext={handleNext}
          handleSkipSecQuestionStep={handleSkipSecQuestionStep}
          setSecurityQuestionsData={setSecurityQuestionsData}
        />
      );
    case TWO:
      return (
        <SetSecurityQuestionsNew
          handleNext={handleNext}
          orgId={orgIdParam}
          loginId={loginIdParam}
          token={tokenParam}
          envUrl={envUrlParam}
          numOfQues={numOfQuesParam}
          isUserActivation={isUserActivationParam}
        />
      );
    case THREE:
      return <AccountActivated
        orgId={orgIdParam}
        env={envParam}
        envUrl={envUrlParam}
        loginId={loginIdParam}
      />;
    default:
      return <p>Invalid Choice</p>;
    }
  }

  return (
    <div className={styles.mainContainer}>
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <div className={classnames(styles.card, 'col-md-4')}>
            <Stepper activeStep={activeStep} />
            <div>{getStepContent(activeStep)}</div>
            <Footer />
          </div>
        </div>
      </Container>
    </div>
  );
}
