/* eslint max-statements: 0 */
/* eslint-disable max-len */
/* eslint no-underscore-dangle: 0 */
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContextData } from '../features/AppContext';
import { handleCookies, handleRedirect } from './../handleCookies/index';
import { APP_NAME_STUDIO, APP_NAME_USER, FIRST_INDEX, NEGATIVE_ONE, OAUTH_TYPE, prepareLabURL } from '../utilities/constants';
import { sanitizeInput } from '../utilities/sanitizer';
import { triggerSecQuesCall } from '../utilities/securityQuestionAPI';
import { handleApi } from '../utilities/api';
import { getOrgDetailsForThreePartURL } from '../utilities/getOrgEnvDetails';


export const useLoginApi = () => {
  const history = useHistory();
  const { dispatch, state } = useContext(AppContextData);
  const isAccessAllowedOnlyToStudioApp = (data) => {
    return data?.isDashboardEditor;
  };

  const setCookies = (data) => {
    handleCookies(data, state.env_id, state.env_lab, state.authTypeResponse);
    handleRedirect(data, { ...state, app_name: isAccessAllowedOnlyToStudioApp(data) ? APP_NAME_STUDIO: APP_NAME_USER });
  };

  const handleAuthTypeApi = async (stateData) => {
    try {
      // Since this API gets called first even before state params get set,
      // a simple check is needed else the lab will always be from env typed in env field
      let envLab = stateData.env_lab;
      if(!envLab) {
        const { isEnvLabInUrl, envLabFromParams } = getEnvLabPresentInUrl();
        envLab = isEnvLabInUrl ? envLabFromParams: getLabEndPoint(stateData);
        dispatch({
          type: 'ENV_LAB',
          payload: envLab
        });
      }
      const { data: orgData } = await getOrgDetailsForThreePartURL(stateData.org_id, stateData.env_id);
      const { data } = await handleAuthType({ ...stateData, org_id: orgData?.orgId }, envLab);

      if(data.status) {
        let provider = data.data[FIRST_INDEX].provider;
        if(provider && provider.endsWith('/')) {
          provider = provider.slice(FIRST_INDEX, NEGATIVE_ONE);
        }
        dispatch({
          type: 'AUTH_TYPE_SUCCESS',
          payload: { ...data.data[FIRST_INDEX], org_alias: orgData?.orgIdAlias, provider }
        });
        if(data.data[FIRST_INDEX] && data.data[FIRST_INDEX].authType !== OAUTH_TYPE) {
          history.replace('/sign-in');
        }else if(data.data[FIRST_INDEX].authType === OAUTH_TYPE) {
          history.replace('/auth-sign-in');
        }
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOADER',
        payload: false
      });
    }
  };

  const handleLoginApi = async (stateData) => {
    try{
      const { data: authData } = await getOrgDetailsForThreePartURL(stateData.org_id, stateData.env_id);
      const { data: loginData } = await handleSignIn({ ...stateData, org_id: authData?.orgId });
      const data = { ...loginData, orgIdAlias: authData?.orgIdAlias };

      dispatch({
        type: 'LOGIN_SUCCESS'
      });
      if(data.status) {
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: data
        });
        if(isAccessAllowedOnlyToStudioApp(data)) {
          dispatch({
            type: 'APP_NAME',
            payload: APP_NAME_STUDIO
          });
        }
        if(data.istwoFactorAuthEnabled === true) {
          window.location.pathname = '/TwoFactorAuth';
        }else if (data.isSecurityQuestionNeedToBeAnswered) {
          await triggerSecQuesCall(data, state, false);
        }else {
          setCookies(data);
        }
      }
    }catch (e) {
      dispatch({
        type: 'SET_LOADER',
        payload: false
      });
      const message = (() => {
        try {
          return e.response.data['errorMessage'];
        } catch (e) {
          return 'Something went wrong. Please try again';
        }
      })();
      if (e.response.data.errorCode === 'SES5045') {
        history.replace('/forgot-password', { message, email: stateData.login_id });
      }
      dispatch({
        type: 'SET_ERROR',
        payload: message
      });
    }
  };

  const handleAuthLoginApi = async (stateData, token) => {
    try{
      const { data: orgData } = await getOrgDetailsForThreePartURL(stateData.org_id, stateData.env_id);
      const { data: loginData } = await handleAuthSignIn({ ...stateData, org_id: orgData?.orgId }, token);
      const data = { ...loginData, orgIdAlias: orgData?.orgIdAlias };

      if(data.status) {
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: data
        });
        if(data.istwoFactorAuthEnabled === true) {
          window.location.pathname = '/TwoFactorAuth';
        }else {
          setCookies(data);
        }
      }
    }catch (e) {
      dispatch({
        type: 'SET_LOADER',
        payload: false
      });
      const message = (() => {
        try {
          return e.response.data['errorMessage'];
        } catch (e) {
          return 'Something went wrong. Please try again';
        }
      })();
      dispatch({
        type: 'SET_ERROR',
        payload: message
      });
      const webLogoutURL = e.response?.data?.['webLogOutUrl'];
      if(webLogoutURL) {
        const returnTo = `${webLogoutURL}?post_logout_redirect_uri=https://${window.location.host}`;
        window.location.href = `https://${stateData.authTypeResponse?.provider}/v2/logout/?client_id=${stateData.authTypeResponse?.clientId}&returnTo=${returnTo}`;
      } else {
        window.location.href = `https://${stateData.authTypeResponse?.provider}/v2/logout/?federated&client_id=${stateData.authTypeResponse?.clientId}&returnTo=https://${window.location.host}`;
      }
    }
  };

  const getLabEndPoint = ({ env_id, env_lab }) => prepareLabURL(env_id, env_lab);

  const getEnvLabPresentInUrl = () => {
    const queryString = window.location.search;
    let isEnvLabInUrl = false;
    let envLabFromParams;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      envLabFromParams = params.get('lab');
      if(envLabFromParams) {
        isEnvLabInUrl = true;
        return { isEnvLabInUrl, envLabFromParams };
      }
    }
    return { isEnvLabInUrl, envLabFromParams };
  };

  const handleSignIn = async (stateData) => {
    const endPoint ='session/login';
    const payload = getPayloadForSignInApi(stateData);
    const envLab = getLabEndPoint(stateData);
    const apiEndPoint = `${envLab}/${endPoint}`;
    return handleApi(apiEndPoint, payload);
  };

  const handleAuthSignIn = async (stateData, token) => {
    const endPoint ='auth/validateAuthResponse';
    const payload = getPayloadForAuthApi(stateData, token);
    const envLab = getLabEndPoint(stateData);
    const apiEndPoint = `${envLab}/${endPoint}`;
    return handleApi(apiEndPoint, payload);
  };

  const handleAuthType = async (stateData, envLab) => {
    const endPoint ='auth/getAuthType';
    const payload = getPayloadForAuthTypeApi(stateData);
    const apiEndPoint = `${envLab}/${endPoint}`;
    return handleApi(apiEndPoint, payload);
  };

  const getPayloadForAuthTypeApi = (stateData) => {
    const auth = stateData;
    return {
      target: 'web',
      orgId: auth.org_id,
      'Content-Type': 'application/json'
    };
  };

  const getPayloadForSignInApi = (stateData) => {
    const signInData = stateData;
    return {
      loginId: signInData.login_id,
      password: signInData.password,
      orgId: signInData.org_id,
      target: 'web',
      'Content-Type': 'application/json'
    };
  };

  const getPayloadForAuthApi = (stateData, token) => {
    return {
      'Content-Type': 'application/json',
      'orgId': sanitizeInput(stateData.org_id),
      'token': token.__raw,
      target: 'web'
    };
  };

  return {
    handleAuthTypeApi,
    handleLoginApi,
    handleAuthLoginApi
  };
};
