//
import axios from 'axios';
import { log } from './logger';
import { FIRST_INDEX, getLocaleHeaders, prepareLabURL } from './constants';

import { isEmpty }
  from 'lodash';

export const fetchPasswordValidations = async (orgId, envId, envLab) => {
  let data = [];
  const headers = { 'orgId': orgId };
  try {
    const response = await axios({
      url: `${prepareLabURL(envId, envLab)}/user/getValidations`,
      headers: { ...headers, ...getLocaleHeaders() },
      'Content-Type': 'application/json',
      method: 'POST',
      data: {}
    });
    if(!isEmpty(response.data) && !isEmpty(response.data.data)) {
      data = response.data.data[FIRST_INDEX];
    }
  } catch (err) {
    !isEmpty(err.response.data.errorMessage)?
      log(err.response.data.errorMessage) :
      log('Error in fetchPasswordValidations');
  }
  return data;
};
