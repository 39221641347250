import TYPES, { ZERO, ONE, TWO, THREE } from '../../utilities/constants';
import { QUERY_BATCH_SIZE } from '../../utilities/constants';
import { getLocalString } from '../../languages/index';
import EnglishUS from '../assests/English(US).svg';
import FrenchCA from '../assests/French(CA).svg';
import SpanishMX from '../assests/Spanish(MX).svg';
import PortuguesePT from '../assests/Portuguese(PT).svg';
import JapaneseJP from '../assests/Japanese(JP).svg';
import StepsActive01 from '../assests/StepsActive01.svg';
import StepsActive02 from '../assests/StepsActive02.svg';
import StepsActive03 from '../assests/StepsActive03.svg';
import StepsActive04 from '../assests/StepsActive04.svg';
import StepsInactive01 from '../assests/StepsInactive01.svg';
import StepsInactive02 from '../assests/StepsInactive02.svg';
import StepsInactive03 from '../assests/StepsInactive03.svg';
import StepsInactive04 from '../assests/StepsInactive04.svg';
export const Fields = [
  {

    label: getLocalString('org_name_label'),
    placeholder: getLocalString('org_name_placeholder'),
    stateKey: 'orgId',
    type: TYPES.TEXT,
    disabled: true,
    readOnly: true,
    tooltip: false
  },
  {
    label: getLocalString('name_label'),
    placeholder: getLocalString('name_placeholder'),
    stateKey: 'name',
    type: TYPES.TEXT,
    readOnly: true,
    disabled: true,
    tooltip: false
  },
  {
    label: getLocalString('email_label'),
    placeholder: getLocalString('email_placeholder'),
    stateKey: 'loginId',
    type: TYPES.EMAIL,
    disabled: true,
    readOnly: true,
    tooltip: false
  },
  {
    label: getLocalString('create_password_label'),
    placeholder: getLocalString('enter_password_placeholder'),
    stateKey: 'password',
    type: TYPES.PASSWORD,
    disabled: false,
    tooltip: true
  },
  {
    label: getLocalString('confirm_password_label'),
    placeholder: getLocalString('confirm_password_placeholder'),
    stateKey: 'passwordConfirmation',
    type: TYPES.PASSWORD,
    disabled: false,
    tooltip: false
  }
];
export const FieldsList = [
  {
    labelstr: 'email_label',
    placeholderstr: 'email_placeholder',
    label: getLocalString('email_label'),
    placeholder: getLocalString('email_placeholder'),
    stateKey: 'loginId',
    type: TYPES.EMAIL,
    disabled: true,
    tooltip: false,
    showEyeIcon: false,
    renderField: true
  },
  {
    labelstr: 'create_password_label',
    placeholderstr: 'enter_password_placeholder',
    label: getLocalString('create_password_label'),
    placeholder: getLocalString('enter_password_placeholder'),
    stateKey: 'password',
    type: TYPES.PASSWORD,
    disabled: false,
    tooltip: true,
    showEyeIcon: true,
    renderField: true
  },
  {
    labelstr: 'confirm_password_label',
    placeholderstr: 'confirm_password_placeholder',
    label: getLocalString('confirm_password_label'),
    placeholder: getLocalString('confirm_password_placeholder'),
    stateKey: 'passwordConfirmation',
    type: TYPES.PASSWORD,
    disabled: false,
    tooltip: false,
    showEyeIcon: true,
    renderField: true
  }
];

export const numofQuesQuery = [{
  'models': [
    'EntityConfig'
  ],
  'columns': {
    'configKey': 'EntityConfig.configKey',
    'configScope': 'EntityConfig.configScope',
    'configValue': 'EntityConfig.configValue'
  },
  'inputs': {
    'filter': 'numberOfSecurityQuestions'
  },
  'filter': '{$configKey}={@filter}',
  'batchSize': QUERY_BATCH_SIZE,
  'batchNumber': ONE,
  'getCount': true
}];

export const activeQuesQuery = [{
  'filter': '{$active} is true',
  'models': ['SecurityQuestions'],
  'columns': {
    'id': 'SecurityQuestions.id',
    'name': 'SecurityQuestions.question',
    'active': 'SecurityQuestions.active'
  },
  'batchSize': QUERY_BATCH_SIZE,
  'batchNumber': ONE
}];


export const languagesList = [
  {
    name: 'English (US)',
    code: 'en-US',
    icon: EnglishUS
  },
  {
    name: 'French',
    code: 'fr-CA',
    icon: FrenchCA
  },
  {
    name: 'Spanish',
    code: 'es-MX',
    icon: SpanishMX
  },
  {
    name: 'Portuguese',
    code: 'pt-PT',
    icon: PortuguesePT
  },
  {
    name: 'Japanese',
    code: 'ja',
    icon: JapaneseJP
  }
];

export const svgList = [
  {
    step: ZERO,
    activeIcon: StepsActive01,
    inactiveIcon: StepsInactive01
  },
  {
    step: ONE,
    activeIcon: StepsActive02,
    inactiveIcon: StepsInactive02
  },
  {
    step: TWO,
    activeIcon: StepsActive03,
    inactiveIcon: StepsInactive03
  },
  {
    step: THREE,
    activeIcon: StepsActive04,
    inactiveIcon: StepsInactive04
  }
];