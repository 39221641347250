import React, { useContext } from 'react';
import {
  TextField
} from '../../components/TextField';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';


function PasswordField (props) {


  const { dispatch } = useContext(AppContextData);


  const handleChange = (e) => {

    dispatchEnvValue(e.target.value);
  };

  const dispatchEnvValue = (value) => {
    dispatch({
      type: 'PWD_CHANGE',
      payload: value
    });
  };

  return (

    <FieldWithLabel
      style={{
        marginBottom: '1rem'
      }}
      field={
        <TextField
          readOnly={props.isReadOnly}
          type="password"
          placeholder={getLocalString('enter_password_placeholder')}
          onChange={handleChange}
          maxLength = {255}
        />
      }
      label={getLocalString('signin_password_label')}
    />
  );
}

export default PasswordField;