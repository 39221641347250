import React, { useEffect, useContext } from 'react';
import {
  Button as ButtonComponent
} from '../../components/Button';
import { AppContextData } from '../AppContext';
import isEmpty from 'lodash/isEmpty';
import { useLoginApi } from '../../apiHooks/apiHooks';
import { getLocalString } from '../../languages';

export function ButtonBase () {

  const { state: authData, dispatch } = useContext(AppContextData);
  const { handleAuthTypeApi } = useLoginApi();

  const isDisabled = () => {
    return isEmpty(authData.org_id) || isEmpty(authData.env_id );
  };

  useEffect(() => {
    document.addEventListener('keypress',
      (handleClickOutside) );
    // Specify how to clean up after this effect:
    return function cleanup () {
      document.removeEventListener('keypress', handleClickOutside);
    };
  }, [authData.org_id, authData.env_id]);

  const handleClickOutside = (e) => {
    if( !isDisabled() && e.code === 'Enter') {
      handleClick(e);
    }
  };

  const dispatchAuthType = () => {
    dispatch({
      type: 'SET_LOADER',
      payload: true
    });
    handleAuthTypeApi(authData);
  };

  const handleClick = (event) => {
    event.preventDefault();
    dispatchAuthType();
  };

  return (
    <ButtonComponent
      type={'primary'}
      style={{
        width: '100%'
      }}
      onClick={handleClick}
      disabled={isDisabled()}
    >
      {getLocalString('continue_button', 'Continue')}
    </ButtonComponent>
  );
}

