/* eslint-disable max-len */
/* eslint-disable complexity*/
/* eslint max-statements: 0 */
import React, { useEffect, useState } from 'react';
import { FieldsList } from './ConstantField';
import { FormField } from './FormField';
import { Button } from '../../components/Button';
import { getLocalString } from '../../languages';
import { hasCrossScript, sanitizeInput } from '../../utilities/sanitizer';
import { fetchPasswordValidations } from '../../utilities/passwordValidationUtilsNew';
import { isEmpty, isEqual } from 'lodash';
import { AlertControlled } from '../../components/AlertControlled/AlertControlled';
import TYPES from '../../utilities/constants';
import styles from './styleNew.module.css';
import '../UserActivation-Contained/style.css';
import View from '../assests/View.svg';
import Hide from '../assests/Hide.svg';

export function UserActivationNew (prop) {
  const passwordKey = 'password';
  const passwordConfirmationKey = 'passwordConfirmation';
  const orgId = sanitizeInput(prop.orgId);
  const envId = prop.env;
  const envLab = prop.envLab;
  const loginId = sanitizeInput(prop.loginId);
  const [passwordHint, setPasswordHint] = useState('');
  const [passwordRegex, setPasswordRegex] = useState('');
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [validationErrors, setValidationErrors] = useState({
    password: {},
    passwordConfirmation: {}
  });
  const [form, setForm] = useState({
    password: {},
    passwordConfirmation: {}
  });

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  });

  useEffect(
    async function getPasswordValidation () {
      const data = await fetchPasswordValidations(orgId, envId, envLab);
      if (!isEmpty(data)) {
        setPasswordHint(data.pwdHint);
        setPasswordRegex(data.pwdRegex);
      }
    },
    []
  );

  useEffect(
    function checkValidations () {
      const errors = {
        password: validationErrors.password,
        passwordConfirmation: validationErrors.passwordConfirmation
      };
      const validPassword = new RegExp(passwordRegex);
      if ((password !== null && password === '') || (password === null && passwordConfirmation !== null)) {
        errors.password.hasError = true;
        errors.password.success = false;
        errors.password.message = getLocalString('Required');
      } else if (password !== '' && password !== null && !validPassword.test(password)) {
        errors.password.hasError = true;
        errors.password.success = false;
        errors.password.message = getLocalString(
          'password_doesnt_meet_requirements'
        );
      } else if (password !== '' && password !== null && validPassword.test(password)) {
        errors.password.hasError = false;
        errors.password.success = true;
        errors.password.message = getLocalString('password_meet_requirements');
      }

      if (!passwordConfirmation && passwordConfirmation === '') {
        errors.passwordConfirmation.hasError = true;
        errors.passwordConfirmation.success = false;
        errors.passwordConfirmation.message = getLocalString('Required');
      } else if (passwordConfirmation !== '' && passwordConfirmation !== null && password!==passwordConfirmation) {
        errors.passwordConfirmation.hasError = true;
        errors.passwordConfirmation.success = false;
        errors.passwordConfirmation.message = getLocalString(
          'pf_password_does_not_match'
        );
      } else if (passwordConfirmation !== '' && passwordConfirmation !== null && password===passwordConfirmation) {
        errors.passwordConfirmation.hasError = false;
        errors.passwordConfirmation.success = true;
        errors.passwordConfirmation.message = getLocalString('password_match');
      }
      setValidationErrors(errors);
    },
    [password, passwordConfirmation]
  );

  const onFocus = (stateKey) => {
    const formValue = Object.assign({ ...form });
    formValue[stateKey].touched = true;
    setForm(formValue);
  };

  const onClick = (key) => {
    if (isEqual(key, passwordKey)) {
      setShowPassword((prevState) => !prevState);
    } else if (isEqual(key, passwordConfirmationKey)) {
      setShowPasswordConfirmation((prevState) => !prevState);
    }
  };

  function getSource (key) {
    if (isEqual(key, passwordKey)) {
      return showPassword ? View : Hide;
    } else if (isEqual(key, passwordConfirmationKey)) {
      return showPasswordConfirmation ? View : Hide;
    }
    return '';
  }

  function getType (key, h) {
    if (isEqual(key, passwordKey)) {
      return showPassword ? TYPES.TEXT : TYPES.PASSWORD;
    } else if (isEqual(key, passwordConfirmationKey)) {
      return showPasswordConfirmation ? TYPES.TEXT : TYPES.PASSWORD;
    }
    return h[key];
  }

  function handleStateChange (key, value) {
    if (isEqual(key, passwordKey)) {
      setPassword(value);
    } else if (isEqual(key, passwordConfirmationKey)) {
      setPasswordConfirmation(value);
    }
  }

  function checkForCrossScript () {
    const isPwdCrossScripted =
      hasCrossScript(password) && hasCrossScript(passwordConfirmation);
    if (isPwdCrossScripted) {
      setErrorMessage(getLocalString('pf_user_activation_failed'));
      return;
    }
    setErrorMessage(undefined);
    handleSubmit();
  }

  function handleSubmit () {
    prop.setPasswordData (password, passwordConfirmation);
  }

  function onCloseError () {
    setErrorMessage(undefined);
  }

  function handleFormField () {
    return FieldsList.map((h, index) => (
      <div key={index} className={styles.textInputField}>
        {h['renderField'] ? (
          <FormField
            key={index}
            label={
              <div>
                <div className={styles.labelText}>{getLocalString(h['labelstr'])}</div>
                {h['tooltip'] ? (
                  <div className={styles.passwordHint}>
                    {getLocalString('your_pwd_txt')}
                    <span> {passwordHint}</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            }
            type={getType(h['stateKey'], h)}
            stateKey={h['stateKey']}
            onChange={handleStateChange}
            readOnly={h['readOnly']}
            placeholder={getLocalString(h['placeholderstr'])}
            value={prop[h['stateKey']]}
            disabled={h['disabled']}
            hasFeedback={
              validationErrors[h['stateKey']] &&
              (validationErrors[h['stateKey']]['hasError'] ||
                validationErrors[h['stateKey']]['success']) &&
              form[h['stateKey']] &&
              form[h['stateKey']]['touched']
            }
            hasSuccess={
              validationErrors[h['stateKey']] &&
              validationErrors[h['stateKey']]['success']
            }
            message={
              validationErrors[h['stateKey']] &&
              validationErrors[h['stateKey']]['message']
            }
            onFocus={onFocus}
            onClick={onClick}
            showPassword={showPassword}
            showPasswordConfirmation={showPasswordConfirmation}
            src={getSource(h['stateKey'])}
            showEyeIcon={h['showEyeIcon']}
          />
        ) : null}
      </div>
    ));
  }
  return (
    <div>
      <p className={styles.heading}>{getLocalString('set_password')}</p>
      {errorMessage ? (
        <AlertControlled
          className={styles['alert']}
          type='danger'
          message={errorMessage}
          onClose={onCloseError}
        />
      ) : null}
      {handleFormField()}
      <br/>
      <Button
        className={styles.fullWidth}
        type={Button.TYPES.primary}
        onClick={checkForCrossScript}
        disabled={
          isEmpty(password && passwordConfirmation && orgId && loginId) ||
          validationErrors?.password?.hasError ||
          validationErrors?.passwordConfirmation?.hasError
        }
      >
        {getLocalString('Next')}
      </Button>
    </div>
  );
}