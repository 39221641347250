//
import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.css';
import { Text } from '../Text';
import NegativeCheck from './icon-negative-check.svg';
import PositiveCheck from './icon-positive-check.svg';
import WarningCheck from './icon-warning-check.svg';
import { Close } from './Close';
import { FIVE_THOUSAND } from '../../utilities/constants';


class ControlledAlert extends React.Component {
    static defaultProps = {
      type: 'success',
      leftIconVisible: true,
      rightIconVisible: true,
      temporary: true
    };

    closeTimer = undefined;

    handleCloseAlert () {
      this.props.onClose && this.props.onClose();
    }

    componentDidMount () {
      if (typeof this.props.timeout === 'number'
            && this.props.temporary === true) {
        this.closeTimer = setTimeout(() => {
          this.handleCloseAlert();
        }, this.props.timeout);
      } else if(this.props.defaultTimeout &&
        this.props.temporary) {
        this.closeTimer = setTimeout(() => {
          this.handleCloseAlert();
        }, FIVE_THOUSAND);
      }
    }

    componentWillUnmount () {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
      }
    }

    getLeftIcon (type) {
      if (type === 'success') {
        return (
          <img
            className={styles['iconMargin']}
            src={PositiveCheck}
            alt=""/>
        );
      } else if (type === 'danger') {
        return (
          <img
            className={styles['iconMargin']}
            src={NegativeCheck}
            alt=""/>
        );
      } else if (type === 'warning') {
        return (
          <img
            className={styles['iconMargin']}
            src={WarningCheck}
            alt=""/>
        );
      }
      return null;
    }

    render () {
      const {
        type,
        message,
        onClose,
        style,
        leftIconVisible,
        rightIconVisible,
        className
      } = this.props;
      return (
        <div
          className={
            classnames(styles['controlledAlert'], styles[type], className)}
          style={style}
        >
          <div className={styles['left']}>
            { leftIconVisible?
              this.getLeftIcon(type)
              :
              null
            }
            { <Text className={styles[type]} type="default">{message}</Text> }
          </div>
          <div className={styles['right']}>
            {
              rightIconVisible &&
            <Close
              className={classnames(
                styles[type],
                styles['close']
              )}
              onClick={onClose} />
            }
          </div>
        </div>
      );
    }
}


export const AlertControlled = ControlledAlert;