import { ZERO, ONE } from './constants';

const events = ['onerror', 'onclick', 'onload', 'onmouseover'];
const tags = ['script', 'iframe', 'button', 'link', 'object'];
const keywords = ['javascript:'];

/**
 * sanitizes the given input and removes all the cross script code
 * @param input
 * @return sanitized input
 */
export function sanitizeInput (input) {
  if(input) {
    let sanitizedInput;
    if(typeof input === 'object') {
      sanitizedInput = getSanitizedObject(input);
    } else {
      // replace keywords from string
      sanitizedInput = removeCrossScript(input);
    }
    return sanitizedInput;
  }
  return input;
}

export function getSanitizedObject (data) {
  let sanitizedData;
  if(Array.isArray(data)) {
    sanitizedData = [];
    for (let i = ZERO; i < data.length; i++) {
      sanitizedData[i] = sanitizeInput(data[i]);
    }
  } else {
    sanitizedData = {};
    for(const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = sanitizeInput(data[key]);
      }
    }
  }
  return sanitizedData;
}

/**
 * checks if the given input has any cross scripting code
 * @param input
 * @return boolean value
 */
export function hasCrossScript (input) {
  const crossscript = getCrossScriptInputs();
  let isCrossScripted = false;
  if(input) {
    if(typeof input === 'object') {
      isCrossScripted = isObjectCrossScripted(input);
    } else {
      for (let i = ZERO; i < crossscript.length; i++) {
        if (input.toLowerCase().includes(crossscript[i])) {
          isCrossScripted = true;
          break;
        }
      }
    }
  }
  return isCrossScripted;
}

export function isObjectCrossScripted (data) {
  if(Array.isArray(data)) {
    for (let i = ZERO; i < data.length; i++) {
      if(hasCrossScript(data[i])) {
        return true;
      }
    }
  } else {
    for(const key in data) {
      if (data.hasOwnProperty(key) && hasCrossScript(data[key])) {
        return true;
      }
    }
  }
  return false;
}

/**
 * checks if input is html code and remove the tags that can be malicious
 * @param input
 * @return sanitized input from cross script tags
 */
export function removeTags (input) {
  const div = document.createElement('div');
  div.innerHTML = input;
  const scripts = div.querySelectorAll(tags.join(', '));
  let i = scripts.length;
  while (i) {
    i = i - ONE;
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
}

/**
 * removes the javascript events from all the html elements
 * @param input
 * @return sanitized input from events
 */
export function removeEvents (input) {
  const div = document.createElement('div');
  div.innerHTML = input;
  const children = div.getElementsByTagName('*');
  if(children && children.length > ZERO) {
    for (let i = ZERO; i < children.length; i++) {
      events.forEach((item) => {
        children[i].removeAttribute(item);
      });
      /*
      as attributes can also be the source of the script through few tags,
      check and remove the attribute having the script
      */
      const attributes = Array.prototype.slice.call(children[i].attributes);
      if(attributes && attributes.length > ZERO) {
        attributes.forEach((item) => {
          if(item.value && item.value.startsWith('javascript:')) {
            children[i].removeAttribute(item.name);
          }
        });
      }
    }
  }
  return div.innerHTML;
}

/**
 * remove all the cross script events
 * and tags from the provided text or string input
 * @param input
 * @return sanitized text/html content from cross script code
 */
export function removeCrossScript (input) {
  let text = input;
  if(typeof text === 'string') {
    const crossscript = getCrossScriptInputs();
    for(let i = ZERO; i < crossscript.length; i++) {
      const regex = new RegExp(crossscript[i], 'gi');
      text = text.replace(regex, '');
    }
  }
  return text;
}

/**
 * @return array of cross script keys that have to be checked for malicious code
 */
export function getCrossScriptInputs () {
  const htmlTags = [...tags];
  const scriptEvents = [...events];
  for(let i = ZERO; i < htmlTags.length; i++) {
    htmlTags[i] = '<' + htmlTags[i];
  }
  for(let i = ZERO; i < scriptEvents.length; i++) {
    scriptEvents[i] = scriptEvents[i] + '=';
  }
  return htmlTags.concat(scriptEvents, keywords);
}