export const FIRST_INDEX = 0;
export const ONE = 1;
export const QUERY_BATCH_SIZE = 50;
export const ZERO = 0;
export const TWO_HUNDRED = 200 ;
export const THREE = 3;
export const TWO = 2;
export const FOUR = 4;
export const NEGATIVE_ONE = -1;

export const FIVE_THOUSAND = 5000;
export const OAUTH_TYPE = 'OAuth';
export const TWO_HUNDRED_FIFTY_FIVE = 255;
export const LAST_TWO_IN_ARRAY = -2;

export const TYPES = {
  URL: 'url',
  EMAIL: 'email',
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number'
};

export const getDomain = () => {
  if(window.location.hostname === LOCAL_HOST) {
    return 'devzinier.net';
  }
  return window.location.hostname.split('.').slice(LAST_TWO_IN_ARRAY).join('.');
};

export const prepareLabURL = (env, lab) => {
  if(lab) {
    return lab;
  }
  return `https://${env}lab.${getDomain()}`;
};

export const APP_NAME_USER = 'app';
export const APP_NAME_STUDIO = 'studio';

export const LOCAL_HOST = 'localhost';

export const isDevelopmentURL =
  () => window.location.hostname.includes('devzinier');

export const isProductionURL =
  () => window.location.hostname.split('.').length === THREE;

export const isDev = () => false;

export const getUrlParts = () => {
  const host = window.location.host;
  const splitUrl = host.split('.');

  if (!host.includes('.')) {
    return [ZERO, []];
  }
  return [splitUrl.length, splitUrl];
};

export const getLocaleHeaders = () => {
  const locale = require('locale2') || 'en-us';
  const localeHeader = {
    'locale': locale ? locale.toLowerCase(): 'en-us',
    'target': 'web'
  };
  return localeHeader;
};
export default TYPES;
