/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint max-lines: 0 */
//
import * as React from 'react';
import parse from 'html-react-parser';
import { FormField } from './FormField';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button';
import axios from 'axios';
import { fetchPasswordValidations }
  from '../../utilities/passwordValidationUtils';
import { isEmpty, pick, forEach, toLower }
  from 'lodash';
import {
  AlertControlled
} from '../../components/AlertControlled/AlertControlled';
import logo from '../Logo/zinier-logo-with-name-black.svg';
import styles from './style.module.css';
import stylesFromSignIn from './../OrgLanding/styles.module.css';
import classnames from 'classnames';
import { Tooltip } from '../../components/Tooltip';
// import { localStorage } from '../../services/LocalStorage/localStorage';
import { ZERO, TWO_HUNDRED, prepareLabURL } from '../../utilities/constants';
import { Fields } from './ConstantField';
import { getLocalString } from '../../languages/index';
import { Checkbox } from '../../components/Checkbox';
import { Text } from '../../components/Text';
import { hasCrossScript, sanitizeInput } from '../../utilities/sanitizer';
import { NavLinkV2 } from '../../components/NavLinkV2';
import { Link } from '../../components/Link';
// import { resetOrgDetails } from '../Landing/resetOrg';
import svg from '../ResetPassword-Contained/icon-question.svg';
import { triggerSecQuesCall } from '../../utilities/securityQuestionAPI';
import { getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';
import useUrlParser from './useUrlParser';

export class UserActivation extends React.Component {

  constructor (props) {
    super(props);
    const locale = require('locale2') || 'en-us';
    this.state = {
      orgId: sanitizeInput(props.orgId),
      envUrl: props.envId,
      envId: props.env,
      envLab: props.envLab,
      openTooltip: false,
      passwordToken: sanitizeInput(props.passwordToken),
      passwordHint: '',
      passwordRegex: '',
      name: sanitizeInput(props.name),
      loginId: sanitizeInput(props.loginId),
      password: '',
      passwordConfirmation: '',
      locale: toLower(locale),
      errorMessage: null,
      successMessage: null,
      form: {
        password: {},
        passwordConfirmation: {}
      },
      validationErrors: {
        password: {},
        passwordConfirmation: {}
      },
      acceptedEula: false,
      eulaTemplate: ''
    };
  }

  componentDidMount () {
    document.addEventListener('keypress',
      ( (this.handleClickOutside)) );
    this.getPasswordValidations();
    this.setEulaTemplate();
  }



  getPasswordValidations = async () => {

    const data = await fetchPasswordValidations(this.state);
    if(!isEmpty(data)) {
      this.setState({
        passwordHint: data.pwdHint || '',
        passwordRegex: data.pwdRegex || ''
      });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keypress',
      ( (this.handleClickOutside)) );
  }

  /**
   * Start Session if clicked on outside of button,
   * while all the fields are available
   */
  handleClickOutside =(e) => {
    const dataAvailable =
      !(isEmpty(
        this.state.password && this.state.passwordConfirmation &&
        this.state.orgId && this.state.name && this.state.loginId
      ) ||
        (
          this.state.validationErrors.password.hasError ||
          this.state.validationErrors.passwordConfirmation.hasError ||
          !this.state.acceptedEula
        ));
    if(dataAvailable && e.code === 'Enter') {
      this.checkForCrossScript();
    }
  };

  /**
   * handleStateChange
   */
  handleStateChange = (key, value) => {
    this.setState({
      [key]: value
    }, this.checkValidations);
  };

  checkValidations = () => { // eslint-disable-line max-statements
    const errors = {
      password: this.state.validationErrors.password,
      passwordConfirmation: this.state.validationErrors.passwordConfirmation
    };
    const validPassword = new RegExp(this.state.passwordRegex);
    if (!this.state.password) {
      errors.password.hasError = true;
      errors.password.success = false;
      errors.password.message = getLocalString('Required');
    } else if (!validPassword.test(this.state.password)) {
      errors.password.hasError = true;
      errors.password.success = false;
      errors.password.message = getLocalString('password_doesnt_meet_requirements');
      this.setState({
        openTooltip: true
      });
    } else {
      errors.password.hasError = false;
      errors.password.success = true;
      errors.password.message = getLocalString('password_meet_requirements');
      this.setState({
        openTooltip: false
      });
    }

    if (!this.state.passwordConfirmation) {
      errors.passwordConfirmation.hasError = true;
      errors.passwordConfirmation.success = false;
      errors.passwordConfirmation.message = getLocalString('Required');
    }else if (this.state.password !== this.state.passwordConfirmation) {
      errors.passwordConfirmation.hasError = true;
      errors.passwordConfirmation.success = false;
      errors.passwordConfirmation.message = getLocalString('pf_password_does_not_match');
    } else {
      errors.passwordConfirmation.hasError = false;
      errors.passwordConfirmation.success = true;
      errors.passwordConfirmation.message = getLocalString('password_match');
    }
    this.setState({
      validationErrors: errors
    });

  }

  onFocus = (stateKey) => {
    const form = Object.assign({ ...this.state.form });
    form[stateKey].touched = true;
    this.setState({
      form: form
    });
  };

  checkForCrossScript = () => {
    const isPwdCrossScripted = hasCrossScript(this.state.password) && hasCrossScript(this.state.passwordConfirmation);
    if(isPwdCrossScripted) {
      this.setState({ errorMessage: getLocalString('pf_user_activation_failed') });
      return;
    }
    this.setState({ errorMessage: null });
    this.handleSubmit();
  }

  /**
   * handleSubmit
   */
  handleSubmit = async () => {
    try {
      const updater = await useUrlParser();
      const { data: { orgId, orgIdAlias } } =
        await getOrgDetailsForThreePartURL(updater.orgId, updater.env);
      updater.orgId = orgId;
      const response = await axios({
        url: `${prepareLabURL(this.state.envId, this.state.envLab)}/user/activateAccount`,
        headers: { 'orgId': orgId, ...pick(
          this.state, [
            'passwordToken',
            'loginId',
            'password',
            'passwordConfirmation',
            'locale',
            'acceptedEula'
          ]) },
        'Content-Type': 'application/json',
        method: 'POST',
        data: {}
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('user_activation_success_text');
        }
      })();
      this.setState({
        successMessage: message
      });
      await triggerSecQuesCall({ ...(response.data || {}), orgIdAlias }, this.state, true);
    } catch (err) {
      this.setState({
        errorMessage: (() => {
          try {
            return err.response.data.errorMessage;
          } catch (e) {
            return '';
          }
        })()
      });
    }
  };

  getPasswordHintArray = (passwordHint) => {
    const pwdHintsArray = [];
    if(!isEmpty(passwordHint)) {
      const pwdHints = passwordHint.split(',');
      if (!isEmpty(pwdHints)) {
        forEach((pwdHints), (item, i) => {
          // eslint-disable-next-line react/react-in-jsx-scope
          pwdHintsArray.push(<li key={i}>{item}</li>);
        });
      }
    }
    return pwdHintsArray;
  }

  toggleAcceptedEula = () => {
    this.setState((prevState) => ({ acceptedEula: !prevState.acceptedEula }));
  }

  getEulaTemplateString = async () => {
    const { locale } = this.state;
    if(locale) {
      const language = locale.split('-')[ZERO];
      try {
        const updater = await useUrlParser();
        const { data: { orgId } } =
        await getOrgDetailsForThreePartURL(updater.orgId, updater.env);
        updater.orgId = orgId;
        const response = axios({
          url: `${prepareLabURL(this.state.envId, this.state.envLab)}/content/eulaConsent`,
          headers: { 'orgId': orgId, ...pick(this.state, [
            'passwordToken',
            'loginId'
          ]) },
          'Content-Type': 'application/json',
          method: 'POST',
          data: {
            'data': [
              {
                'name': language,
                'docType': 'Eula-SignUP'
              }
            ]
          }
        });
        return response;
      } catch (err) {
        this.setState({
          errorMessage: (() => {
            try {
              return err.response.data.errorMessage;
            } catch (e) {
              return '';
            }
          })()
        });
        return '';
      }
    }
    return '';
  }

  setEulaTemplate = async () => {
    const response = await this.getEulaTemplateString();
    if(response && response.status === TWO_HUNDRED && response.data.status) {
      this.setState({
        eulaTemplate: response.data.data[ZERO].longTextTemplate
      });
    }
  }

  renderEulaText = () => {
    if(this.state.eulaTemplate) {
      return (
        <div className={styles.eulaTemplate}>
          {parse(this.state.eulaTemplate)}
        </div>
      );
    }
    return (
      <Text>
        {getLocalString('pf_read_agreed_message',
          'I have read and agree to the Zinier')}&nbsp;
        <a
          href={getLocalString('terms_and_conditions_link',
            'https://www.zinier.com/terms-conditions/')}
          className={styles.anchorTag}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getLocalString('terms_and_conditions',
            'Terms & Conditions')}&nbsp;
        </a>
        {getLocalString('web_and',
          'and')}&nbsp;
        <a
          href={getLocalString('privacy_policy_link',
            'https://www.zinier.com/privacy-policy-app')}
          className={styles.anchorTag}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getLocalString('privacy_policy',
            'Privacy Policy')}
        </a>.
      </Text>
    );
  }

  render () {
    const pwdHintsArray = this.getPasswordHintArray(this.state.passwordHint);
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <img
            src={logo}
            alt={'zinier'}
            className={styles.img}
          />
          <div className={classnames(styles.card, 'col-md-4')}>
            <p className={styles.cardTitle}>
              {getLocalString('confirm_account')}
            </p>
            <div className={styles.borderTextBottomWrapper}>
              <div className={styles.borderTextBottom}/>
            </div>
            { this.state.errorMessage ?
              <AlertControlled
                className={styles['alert']}
                type={'danger'}
                message={this.state.errorMessage}
                onClose={
                  (() => {
                    this.setState({ errorMessage: null });
                  })
                }
              />: null
            }
            { this.state.successMessage ?
              <AlertControlled
                className={styles['alert']}
                type={'success'}
                message={this.state.successMessage}
                onClose={
                  (() => {
                    this.setState({ successMessage: null });
                  })
                }
              />: null
            }
            {
              Fields.map((h, index) => <FormField
                key={index}
                label={
                  <div className={styles.centerItem}>
                    {h['label']}
                    {h['tooltip'] ?
                      <Tooltip
                        open={this.state.openTooltip}
                        html={
                          <div className={styles.tooltipText} >
                            <ul>
                              {getLocalString('your_pwd_txt')}
                              <br/>
                              {!isEmpty(pwdHintsArray)?
                                pwdHintsArray: this.state.passwordHint}
                            </ul>
                          </div>
                        }
                        position={'top'}>
                        <img
                          src={svg}
                          alt='question'
                        />
                      </Tooltip>
                      :''}
                  </div>
                }
                hasFeedback={
                  (this.state.validationErrors[h['stateKey']] &&
                    (this.state.validationErrors[h['stateKey']]['hasError'] ||
                    this.state.validationErrors[h['stateKey']]['success']))
                  &&
                  (this.state.form[h['stateKey']] &&
                    this.state.form[h['stateKey']]['touched'])
                }
                hasSuccess=
                  {this.state.validationErrors[h['stateKey']] &&
                  this.state.validationErrors[h['stateKey']]['success']}
                message={
                  this.state.validationErrors[h['stateKey']] &&
                  this.state.validationErrors[h['stateKey']]['message']
                }
                type={h['type']}
                stateKey={h['stateKey']}
                onChange={this.handleStateChange}
                readOnly={h['readOnly']}
                placeholder={h['placeholder']}
                value={this.state[h['stateKey']]}
                disabled={h['disabled']}
                onFocus={this.onFocus}
              />)
            }
            <div className={styles.eulaContainer}>
              <div className={styles.eulaCheckbox}>
                <Checkbox
                  onChange={this.toggleAcceptedEula}
                  checked={this.state.acceptedEula}
                />
              </div>
              {this.renderEulaText()}
            </div>
            <Button
              className={styles.fullWidth}
              onClick={this.checkForCrossScript}
              type={Button.TYPES.primary}
              disabled={isEmpty(
                this.state.password && this.state.passwordConfirmation &&
                this.state.orgId && this.state.name && this.state.loginId
              ) ||
              (
                this.state.validationErrors.password.hasError ||
                this.state.validationErrors.passwordConfirmation.hasError ||
                !this.state.acceptedEula
              )}>
              {getLocalString('submit_button')}
            </Button>
          </div>
          <div className={
            classnames(stylesFromSignIn['redirectOrdId'], 'col-md-4')}>
            <p>{getLocalString('pf_already_have_account')}
              <NavLinkV2
                classname={stylesFromSignIn.linkWrapper}
                isUnstyled={true}
                onClick={() => true}>
                <Link
                  isUnStyled={false}
                  to={ '/'}
                  className={stylesFromSignIn.orgidLink}
                >
                  {getLocalString('pf_here_link')}
                </Link></NavLinkV2>
            </p>
          </div>
        </div>
      </Container>
    );
  }
}
