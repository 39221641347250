/* eslint-disable max-len */
import React from 'react';
import styles from './styles.module.css';

export function NoRouteMatchedproxy () {


  return (
    <div className={styles.Aligner}>
      <div className={[styles.AlignerItem, styles.AlignerItemFixed]}>
        <h1>404</h1>
        <h3> We could not find any route <code>{window.location.pathname}</code> in our system.
        </h3>
      </div>
    </div>
  );

}


