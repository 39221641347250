import axios from 'axios';

export const handleApi = async (endPoint, payload) => {
  const request = {
    method: 'post',
    url: endPoint,
    data: {},
    headers: getDefaultHeaders(payload)
  };
  return axios(request);

};
const getDefaultHeaders = (headers) => {
  const locale = require('locale2') || 'en-us';
  const h = {
    'Content-Type': 'application/json',
    'locale': locale ? locale.toLowerCase() : 'en-us',
    'target': 'web',
    ...headers
  };
  return h;
};