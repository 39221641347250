/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AppContextData } from '../AppContext';
import { OAUTH_TYPE } from '../../utilities/constants';
// eslint-disable-next-line
export const validateRoute = BaseComponet => props => {
  const { state } = useContext(AppContextData);
  useEffect(() => {
    if ((isEmpty(state.org_id) || isEmpty(state.env_id))) {
      window.location.pathname = '/';
    }
  }, []);

  useEffect(() => {
    if ( (window.location.pathname?.startsWith('/sign-in')||
          window.location.pathname?.startsWith('/twoFactorAuth') ||
          window.location.pathname?.startsWith('/forgot-password'))
          && (state.authTypeResponse.authType === OAUTH_TYPE || isEmpty(state.authTypeResponse))) {
      window.location.pathname = '/';
    }
  }, []);


  return (
    <BaseComponet {...props}/>
  );
};