/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { useEffect }from 'react';
import { OrgLanding } from '../OrgLanding/index';
import { SignInProxy } from '../SignIn';
import { DataProvider } from '../AppContext';
import { Route, Router, Switch } from 'react-router-dom';
import { TwoFactorAuth } from '../TwoFactorAuthentication';
import { ForgotPasswordProxy } from '../ForgotPassword/ForgotPassword';
import { NoRouteMatchedproxy } from './noRouteMatch';
import { createBrowserHistory } from 'history';
import { ResetPassword } from '../ResetPassword-Contained/ResetPasswordContainer';
import { SetSecurityQuestions } from '../UserActivation-Contained/SetSecurityQuestions';
import { MobileAccessPage } from '../UserActivation-Contained/MobileAccessPage';
import { MobileSignUpPage } from '../UserActivation-Contained/MobileSignUpPage';
import { FIRST_INDEX } from '../../utilities/constants';
import { OAuthLanding } from '../AuthLanding/OAuthLanding';
import { UserActivationFlow } from '../UserActivationFlow/UserActivationFlow';
import { UserActivationContainer } from '../UserActivation-Contained/UserActivationContainer';
// import { UserActivationContainerNew } from '../UserActivation-Contained/UserActivationContainerNew';

export const history = createBrowserHistory();
const metaTagForDeviceWidth = ( ) => {
  const metaTag = document.getElementsByTagName('META');
  for (let i=0; i<metaTag.length; i++) {
    if (metaTag[i].getAttribute('name') === 'viewport') {
      const meta = ( ( ( metaTag[i])));
      const body = document.getElementsByTagName('body');
      if(
        (window.location.pathname === '/sign-in') ||
          (window.location.pathname === '/forgot-password') ||
          (window.location.pathname === '/reset-password') ||
          (window.location.pathname === '/twoFactorAuth') ||
          ( window.location.pathname === '/')
      ) {
        meta.content =
            'width=device-width, initial-scale=1, shrink-to-fit=no';
        body[FIRST_INDEX].classList.add('responsive');
      } else if(
        (window.location.pathname === '/user-activation')
      ) {
        meta.content =
            'name="viewport" content="width=device-width, initial-scale=1.0';
        document.getElementById('root').classList.add('responsiveContainer');
      } else {
        meta.content =
            'width=1440, initial-scale=1, shrink-to-fit=no';
        body[FIRST_INDEX].classList.remove('responsive');
      }
    }
  }
};

export const Home = () => {
  useEffect(() => {
    metaTagForDeviceWidth();
  }, []);
  return (
    <div>

      <DataProvider>
        <Router history={history}>
          <Switch >
            <Route component={OrgLanding} path='/' exact />
            <Route component={SignInProxy} path='/sign-in' exact/>
            <Route component={TwoFactorAuth} path='/twoFactorAuth' exact/>
            <Route component={ForgotPasswordProxy} path='/forgot-password' exact/>
            <Route component={ResetPassword} path='/reset-password' exact/>
            <Route component={UserActivationContainer} path='/user-activation' exact/>
            <Route component={UserActivationFlow} path='/user-activation-new' exact/>
            <Route component={SetSecurityQuestions} path='/setSecurityQues' exact/>
            <Route component={MobileAccessPage} path='/mobileUnlock' exact/>
            <Route component={OAuthLanding} path='/auth-sign-in' exact/>
            <Route component={MobileSignUpPage} path='/deep-link' exact/>
            <Route component={NoRouteMatchedproxy}/>
          </Switch>
        </Router>
      </DataProvider>
    </div>

  );
};
