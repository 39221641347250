/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React from 'react';
import { getLocalString } from '../../languages';
import styles from '../Copyright/style.module.css';

export function Footer () {
  return (
    <div className={styles.footer}>
      <hr className={styles.horizontal_line}/>
      <div className={styles.footer_content}>
        <p className={styles.footer_text1}>
          {getLocalString('copyright')} 2014 - {new Date().getFullYear()} Zinier Inc.</p>
        <p className={styles.footer_text2}>
          {getLocalString('all_rights_reserved')}</p>
      </div>
    </div>
  );
}
