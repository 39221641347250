/*  */
import React from 'react';
import svg from './icon-close.svg';

export const Close = (props) => {
  const { className, onClick } = props;
  const d = 'M15.4 4.6a.849.849 0 0 0-1.2 0L10 8.8 5.8 4.6a.849.849'+
    ' 0 1 0-1.2 1.2L8.8 10l-4.2 4.2a.849.849 0 0 0 1.2 1.2l4.2-4.2 4.2'+
    ' 4.2a.849.849 0 0 0 1.2-1.2L11.2 10l4.2-4.2a.849.849 0 0 0 0-1.2z';
  return (
    <svg
      className={className}
      style={{ fill: '#A5A5A5' }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d={d}/>
    </svg>
  );
};

Close.svg = svg;
