/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import { AppContextData } from '../AppContext';
import { areCookiesAvailable, getOrgID, handleRedirect } from '../../handleCookies';
// eslint-disable-next-line
export const validateSession = BaseComponet => props => {
  const orgCookie = getOrgID();
  const { state, dispatch } = useContext(AppContextData);
  useEffect(() => {
    if(areCookiesAvailable()) {
      dispatch({
        type: 'SET_LOADER',
        payload: true
      });
      handleRedirect({ orgId: orgCookie }, state);
    }
  }, []);
  return (
    <BaseComponet {...props}/>
  );
};