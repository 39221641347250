import React, { useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  Button as ButtonComponent
} from '../../components/Button';
import { AppContextData } from '../AppContext';
import { useLoginApi } from '../../apiHooks/apiHooks';
import { getLocalString } from '../../languages';
import { OAUTH_TYPE } from '../../utilities/constants';
import { useHistory } from 'react-router-dom';

export function ButtonBaseLogin () {

  const { state: authData, dispatch } = useContext(AppContextData);
  const { handleLoginApi } = useLoginApi();
  const history = useHistory();
  const isDisabled = () => {
    return isEmpty(authData.login_id) ||
      isEmpty(authData.password) || isEmpty(authData.org_id);
  };

  useEffect(() => {
    document.addEventListener('keypress',
      (handleClickOutside) );
    // Specify how to clean up after this effect:
    return function cleanup () {
      document.removeEventListener('keypress', handleClickOutside);
    };
  }, [authData.login_id, authData.password]);

  const handleClickOutside = (e) => {
    if( !isDisabled() && e.code === 'Enter') {
      handleClick(e);
    }
  };


  const handleClick = (e) => {
    e.preventDefault();
    if(authData.authTypeResponse.authType !== OAUTH_TYPE) {
      dispatch({
        type: 'SET_LOADER',
        payload: true
      });
      dispatch({
        type: 'SET_ERROR',
        payload: null
      });
      handleLoginApi(authData);
    }else{
      history.replace('/');
    }
  };

  return (
    <ButtonComponent
      type={'primary'}
      style={{
        width: '100%'
      }}
      onClick={handleClick}
      disabled={isDisabled()}
    >
      {getLocalString('signin_button')}
    </ButtonComponent>
  );
}

