import React, { useState, useEffect, useContext } from 'react';
import {
  TextField
} from '../../components/TextField';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';


function LoginIdField () {

  const { dispatch, state } = useContext(AppContextData);
  const [loginID, setloginID] = useState(state.login_id || '');
  useEffect(() => {

    dispatchEnvValue(loginID);
  }, []);

  const handleChange = (e) => {
    setloginID(e.target.value);
    dispatchEnvValue(e.target.value);
  };

  const dispatchEnvValue = (value) => {
    dispatch({
      type: 'LOGIN_ID',
      payload: value
    });
  };

  return (

    <FieldWithLabel
      style={{
        marginBottom: '1rem'
      }}
      field={
        <TextField
          readOnly={false}
          type="email"
          placeholder={getLocalString('enter_email_placeholder')}
          onChange={handleChange}
          value={loginID}
          maxLength = {255}
        />
      }
      label={getLocalString('email_label')}
    />
  );
}

export default LoginIdField;