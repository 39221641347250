import React, { useState, useContext, useEffect } from 'react';
import {
  TextField
} from '../../components/TextField';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { AppContextData } from '../AppContext';
import { getLocalString } from '../../languages';

function EnvIdField (props) {

  const [envName, setEnvname] = useState(props.envName);
  const { dispatch } = useContext(AppContextData);

  useEffect(() => {
    dispatchEnvValue(envName);
  }, []);

  const handleChange = (e) => {
    setEnvname(e.target.value);
    dispatchEnvValue(e.target.value);
  };

  const dispatchEnvValue = (value) => {
    dispatch({
      type: 'ENV_ID',
      payload: value.toLowerCase()
    });
  };

  return (

    <FieldWithLabel
      style={{
        marginBottom: '1rem'
      }}
      field={
        <TextField
          readOnly={props.isReadOnly}
          type="text"
          placeholder={getLocalString('pf_enter_env_name_placeholder',
            'Enter environment name')}
          onChange={handleChange}
          value={envName}
          maxLength = {255}
        />
      }
      label={getLocalString('pf_env_name_label', 'Environment Name')}
    />
  );
}

export default EnvIdField;