/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useState } from 'react';
import axios from 'axios';
import Tabs from './Tabs';
import { Loader } from '../../components/Loader/Loader';
import { getLocalString } from '../../languages';
import { Button as ButtonComponent } from '../../components/Button/index';
import { triggerSecQuesCall } from '../../utilities/securityQuestionAPINew';
import { AlertControlled } from '../../components/AlertControlled/AlertControlled';
import { prepareLabURL } from '../../utilities/constants';
import styles from './style.module.css';

const TermsAndPrivacy = (props) => {
  const locale = require('locale2') || 'en-us';
  const { env, envLab, envId } = props;
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const buttonEnable = () => {
    return !checked;
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const headers = {
        orgId: props.orgId,
        passwordToken: props.passwordToken,
        loginId: props.loginId,
        password: props.passwordParam,
        passwordConfirmation: props.passwordConfirmationParam,
        locale: locale,
        acceptedEula: checked
      };
      const response = await axios({
        url: `${prepareLabURL(env, envLab)}/user/activateAccount`,
        headers: { ...headers },
        'Content-Type': 'application/json',
        method: 'POST',
        data: {}
      });

      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('user_activation_success_text');
        }
      })();

      setSuccessMessage(message);

      await triggerSecQuesCall(
        response.data,
        envId,
        env,
        envLab,
        true,
        props.handleNext,
        props.handleSkipSecQuestionStep,
        props.setSecurityQuestionsData
      );
      setIsLoading(false);
    } catch (err) {
      setErrorMessage(err.response.data.errorMessage);
      setIsLoading(false);
    }
  };
  function onCloseError () {
    setErrorMessage(undefined);
  }

  function onCloseSuccess () {
    setSuccessMessage(undefined);
  }

  return (
    <>
      { isLoading &&
        <Loader type='fullScreen'/>
      }
      <p className={styles.header}>{getLocalString('terms')} & {getLocalString('privacy_policy')}</p>
      {errorMessage ? (
        <AlertControlled
          className={styles['alert']}
          type='danger'
          message={errorMessage}
          onClose={onCloseError}
        />
      ) : null}
      {successMessage ? (
        <AlertControlled
          className={styles['alert']}
          type='success'
          message={successMessage}
          onClose={onCloseSuccess}
        />
      ) : null}
      <p className={styles.headerContent}>
        {getLocalString('terms_and_privacy_message')}
      </p>
      <Tabs/>
      <br/>
      <label className={styles.checkbox}>
        <input type='checkbox' onChange={handleChange} />
        &nbsp;&nbsp;&nbsp;{getLocalString('pf_read_agreed_message')} {getLocalString('terms_and_conditions')} {getLocalString('web_and')} {getLocalString('privacy_policy')}
      </label>
      <div className={styles.topBufferTerms}>
        <ButtonComponent
          className={styles.fullWidthTerms}
          type='primary'
          disabled={buttonEnable()}
          onClick={handleSubmit}
        >
          {getLocalString('Next')}
        </ButtonComponent>
      </div>
    </>
  );
};

export default TermsAndPrivacy;
