
import { queryString } from './queryString';

const decode = (encoded) => {
  return new Buffer(encoded || '', 'base64').toString('utf8');
};

const urlDecode = (encoded) => {
  const enocderConst = 4;
  let escapedEncoded = encoded.replace('-', '+').replace('_', '/');

  while (escapedEncoded.length % enocderConst) {
    escapedEncoded += '=';
  }
  return decode(escapedEncoded);
};

export const decodeHashToParams = hash => {
  return queryString.parse(urlDecode(hash));
};
