/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NEGATIVE_ONE, ZERO, getLocaleHeaders } from '../../utilities/constants';
import { getLocalString } from '../../languages';
import { TextField } from '../../components/TextField/index';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { activeQuesQuery } from './ConstantField';
import { AppContextData } from '../AppContext';
import { isEmpty, lowerFirst } from 'lodash';
import { Select } from '../../components/Select';
import { AlertControlled } from '../../components/AlertControlled/AlertControlled';
import { Button as ButtonComponent } from '../../components/Button/index';
import { hasCrossScript } from '../../utilities/sanitizer';
import { handleCookies, handleRedirect } from '../../handleCookies';
import { Loader } from '../../components/Loader/Loader';
import styles from './styleNew.module.css';

export function SetSecurityQuestionsNew (props) {
  const { state } = useContext(AppContextData);
  const [errors, setErrors] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const orgId = props.orgId;
  const loginId = props.loginId;
  const token = props.token;
  const envUrl = props.envUrl;
  const numOfQues = props.numOfQues;
  const isUserActivation = props.isUserActivation;
  useEffect(() => {
    (async function fetchQuestionsRes () {
      await fetchQuestions();
    })();
  }, []);

  async function fetchQuestions () {
    try {
      setIsLoading(true);
      const response = await axios({
        url: `${envUrl}/query/read`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          orgId: orgId,
          loginId: loginId,
          token: token,
          ...getLocaleHeaders()
        },
        data: { data: activeQuesQuery }
      });

      if (response?.data?.data) {
        ((data, numOfQues) => {
          const ques = [];
          const saveData = [];
          data.forEach((itr) => ques.push([itr.id, itr.name]));
          const numberOfQues = numOfQues > ques.length ? ques.length : numOfQues;
          lowerFirst(numberOfQues);
          for (let i = 0; i < numberOfQues; i++) {
            saveData[i] = { id: '', ans: '' };
          }
          setQuestions(ques);
          setUserData(saveData);
        })(response?.data?.data, numOfQues);
      }
      setIsLoading(false);
    } catch (err) {
      setErrors(err?.response?.data?.errorMessage);
      setIsLoading(false);
    }
  }

  function onQuesChange (i, e) {
    const data = [...userData];
    data[i].id = e.target.value;
    data[i].ans = '';
    setUserData(data);
  }

  function checkForCrossScript () {
    const isCrossScripted = hasCrossScript(userData);
    if (isCrossScripted) {
      setErrors(getLocalString('something_went_wrong'));
    }
    handleSubmit();
  }

  async function handleSubmit () {
    setIsButtonDisable(true);
    setErrors(null);
    try {
      const response = await axios({
        url: `${envUrl}/user/saveSecurityAns`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          orgId: orgId,
          loginId: loginId,
          token: token,
          ...getLocaleHeaders()
        },
        data: { data: userData }
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('user_activation_success_text');
        }
      })();
      if (!isUserActivation) {
        handleCookies(
          state.session,
          state.env_id,
          state.env_lab,
          state.authTypeResponse
        );
        handleRedirect(state.session, state);
      }
      setSuccessMessage(message);
      props.handleNext();
    } catch (err) {
      setIsButtonDisable(false);
      setErrors(err?.response?.data?.errorMessage);
    }
  }

  const buttonEnable = () => {
    const stateInd =
      userData &&
      userData.map((item) => {
        return !isEmpty(item.id) && !isEmpty(item.ans);
      });
    return stateInd.includes(false) || isButtonDisable;
  };

  const onchangeAns = (i, e) => {
    const changedState = {
      id: userData[i]?.id,
      ans: e.target.value
    };
    const data = ((changedStateData, userData) => {
      const copyUserData = [...userData];
      if (copyUserData.length === ZERO) {
        return [changedStateData];
      }
      const index = copyUserData
        .map((itr) => itr.id)
        .indexOf(changedStateData.id);
      if (
        index !== NEGATIVE_ONE &&
        copyUserData[index].id === changedStateData.id
      ) {
        copyUserData[index].ans = changedStateData.ans;
        return copyUserData;
      }
      return [...copyUserData, changedStateData];
    })(changedState, userData);
    setUserData(data);
  };

  const selectOptions = (id) => {
    const selectedQuestion = userData.filter((itr) => itr.id !== id);
    let copyQuestions = [...questions];
    copyQuestions = copyQuestions.filter(
      (filter) => !selectedQuestion
        .map((item) => item.id)
        .includes(filter[ZERO]));
    return copyQuestions;
  };

  return (
    <>
      { isLoading &&
        <Loader type='fullScreen'/>
      }
      <p className={styles.header}>
        {getLocalString('select_Security_Questions')}
      </p>
      {errors ? (
        <AlertControlled
          className={styles['alert']}
          message={errors}
          onClose={() => {
            setErrors(null);
          }}
          type='danger'
        />
      ): null}
      {successMessage ? (
        <AlertControlled
          className={styles['alert']}
          type='success'
          message={successMessage}
          timeout={2000}
          onClose={() => {
            setSuccessMessage(null);
          }}
        />
      ) : null}
      {userData &&
        userData.length > ZERO &&
        userData.map((ques, i) => (
          <div key={i}>
            <FieldWithLabel
              field={
                <div>
                  <Select
                    onChange={(e) => onQuesChange(i, e)}
                    optionsData={selectOptions(ques.id)}
                    value={ques.id}
                    prompt={getLocalString('Select_Question')}
                    className={styles['fieldBottom']}
                  />
                  <TextField
                    placeholder={getLocalString('Enter_your_answer')}
                    type='text'
                    disabled={isEmpty(ques.id)}
                    value={ques.ans}
                    onChange={(e) => onchangeAns(i, e)}
                  />
                </div>
              }
              className={styles['topBufferSecurityQuestion']}
            />
          </div>
        ))}
      <div className={styles.topBuffer}>
        <ButtonComponent
          className={styles.fullWidth}
          type='primary'
          disabled={buttonEnable()}
          onClick={checkForCrossScript}
        >
          {getLocalString('Next')}
        </ButtonComponent>
      </div>
    </>
  );
}
