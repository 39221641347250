/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { APP_NAME_USER } from '../../utilities/constants';

const initialState = {
  org_id: '',
  org_alias: '',
  env_id: '',
  error: null,
  app_name: APP_NAME_USER,
  redirect_url: '',
  login_id: '',
  sign_in: false,
  auth_code: '',
  isLoading: false,
  two_factor_auth_data: {
    isAuthMenuHidden: true
  },
  version: ''
};
export const AppContextData = React.createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
  case 'ORG_ID':
    return {
      ...state,
      org_id: action.payload
    };
  case 'ENV_ID':
    return {
      ...state,
      env_id: action.payload
    };
  case 'ENV_LAB':
    return {
      ...state,
      env_lab: action.payload
    };
  case 'SET_LOADER':
    return {
      ...state,
      isLoading: action.payload
    };
  case 'AUTH_TYPE_SUCCESS':
    return {
      ...state,
      isLoading: false,
      authTypeResponse: action.payload
    };
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      isLoading: false,
      session: action.payload
    };

  case 'LOGIN_ID':
    return {
      ...state,
      login_id: action.payload
    };
  case 'PWD_CHANGE':
    return {
      ...state,
      password: action.payload
    };
  case '2FA_CODE_CHANGE':
    return {
      ...state,
      auth_code: action.payload
    };
  case 'SET_ERROR':
    return {
      ...state,
      error: action.payload
    };
  case 'REDIRECT_URL':
    return {
      ...state,
      redirect_url: action.payload
    };
  case 'APP_NAME':
    return {
      ...state,
      app_name: (isEmpty(action.payload) || action.payload === 'user' )? 'app' :action.payload
    };
  case 'CHANGE_AUTH_MENU':
    return {
      ...state,
      two_factor_auth_data: {
        isAuthMenuHidden: action.payload
      }
    };
  case 'LOGIN_VERSION':
    return {
      ...state,
      version: action.payload
    };
  default:
    return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, JSON.parse(localStorage.getItem('appData')) ||initialState);
  useEffect(() => {
    // eslint-disable-next-line
    const { password, error, redirect_url, env_lab, isLoading,...finalData }=state;
    localStorage.setItem('appData', JSON.stringify(finalData));
  }, [state]);

  return (
    <AppContextData.Provider value={{
      state,
      dispatch
    }}>
      {children}
    </AppContextData.Provider>
  );
};
