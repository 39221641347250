import React, { useState, useContext, useEffect } from 'react';

import { AppContextData } from '../AppContext';
import {
  TextField
} from '../../components/TextField';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { getLocalString } from '../../languages';


function OrgIdField (props) {
  const [orgID, setOrgID] = useState(props.orgID);
  const { dispatch } = useContext(AppContextData);
  useEffect(() => {
    dispatchOrgIdValue(orgID);
  }, []);
  const handleChange = (e) => {
    setOrgID(e.target.value);
    dispatchOrgIdValue(e.target.value);
  };

  const dispatchOrgIdValue = (value) => {
    dispatch({
      type: 'ORG_ID',
      payload: value
    });
  };
  return (
    <FieldWithLabel
      style={{
        marginBottom: '1rem'
      }}
      field={
        <TextField
          readOnly={props.isReadOnly}
          type="text"
          placeholder={getLocalString('enter_org_name_placeholder')}
          onChange={handleChange}
          value={orgID}
          maxLength = {255}
        />
      }
      label={getLocalString('org_name_label')}
    />
  );
}

export default OrgIdField;