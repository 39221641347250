/*  */
import * as React from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';
import { Button as ReactstrapButton } from 'reactstrap';

const TYPES = {
  primary: 'primary',
  default: 'default',
  secondary: 'secondary',
  success: 'success'
};



export class Button extends React.Component {
  static TYPES = TYPES;

  render () {
    const props = this.props;
    const { children, onClick, disabled, type, id } = props;
    const btnClass = classnames(styles[type], props.className, {
      [styles.disabled]: props.disabled,
      [styles.noShadow]: !props.isShadowVisible
    });
    return (
      <ReactstrapButton
        onClick={ onClick }
        disabled={ disabled }
        className={ btnClass }
        style={props.style}
        id={id}
      >
        { children }
      </ReactstrapButton>
    );
  }
}
