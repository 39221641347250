/* eslint max-statements: 0 */
/* eslint-disable max-len */
//
import React, { useContext, useEffect, useState } from 'react';
import { Container } from '../../components/Container';

import { OrgId } from './OrgId';
import { LoginId } from './LoginId';
import { Submit } from './Submit';
import axios from 'axios';

import isEmpty from 'lodash/isEmpty';
import {
  AlertControlled as Alert
} from '../../components/AlertControlled/AlertControlled';
import classnames from 'classnames';
import styles from './styles.module.css';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import { NavLinkV2 } from '../../components/NavLinkV2';
import { Link } from '../../components/Link';
import { validateRoute } from './../ValidateRoute/validateRoute';
import stylesFromSignIn from '../OrgLanding/styles.module.css';
import { AppContextData } from '../AppContext';
import { getDomain, getLocaleHeaders, prepareLabURL, TWO_HUNDRED_FIFTY_FIVE } from '../../utilities/constants';
import { getLocalString } from '../../languages';
import { PrivacyLink } from '../../components/PrivacyLink';
import { getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';
import { useLocation } from 'react-router-dom';


function ForgotPassword () {


  const { state: loginData } = useContext(AppContextData);

  const [orgId, setOrgID] =useState('');
  const { state: historyState } = useLocation();
  const [loginId, setLoginId]=useState(historyState?.email || '');
  const [alertMessage, setAlertMessage]=useState(historyState?.message || '');
  const [alertMessageType, setAlertMessageType]=useState(historyState?.message ? 'danger' : 'success');
  const [isSubmitDisable, setSubmitDisable]=useState(false);
  const errorTextOrgId ='';
  const [errorTextLoginId, setErrorTextLoginId]=useState('');

  useEffect(() => {
    document.addEventListener('keypress',
      (handleClickOutside) );
    setOrgID(loginData.org_id);
  }, []);


  useEffect(() => {
    return () => {
      document.removeEventListener('keypress',
        ( (handleClickOutside)) );
    };
  });

  /**
   * Start Session if clicked on outside of button,
   * while all the fields are available
   */
  const handleClickOutside =(e) => {
    const dataAvailable =
      !isEmpty( orgId && loginId);
    if(dataAvailable && e.code === 'Enter') {
      handleSubmit();
    }
  };


  const handleLoginChange = (change) => {
    let errMsg = '';
    const isInValid = change.loginId ?
      validateEmail(change.loginId): false;
    errMsg = isInValid ? getLocalString('invalid_email_address'): '';
    if(change.loginId.length >= TWO_HUNDRED_FIFTY_FIVE) {
      errMsg = validateMaxLength(change.loginId);
    }
    setLoginId(change.loginId);
    setSubmitDisable(isInValid);
    setErrorTextLoginId(errMsg);
  };

  const validateEmail = (value) => {
    // eslint-disable-next-line no-useless-escape
    return !(/^((([!#$%&*+\-/=?^_`{|}~\w])|([!#$%&*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\'\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(value));

  };

  const checkAthuOConnection = () => {

    window.location.pathname ='/sign-in';

  };

  const validateMaxLength = (value) => {
    let err = '';
    if(value && value.length >= TWO_HUNDRED_FIFTY_FIVE) {
      err = getLocalString('enter_valid_input_length');
    }
    return err;
  };

  const handleSubmit = async () => {
    setSubmitDisable(true);
    try {
      const { data: orgData } = await getOrgDetailsForThreePartURL(orgId, loginData.env_id);
      const response = await axios({
        url: `${prepareLabURL(loginData.env_id, loginData.env_lab)}/user/forgotPassword`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'orgId': orgData.orgId,
          'loginId': loginId,
          'internalURL': `https://${orgId}.app.${loginData.env_id}.${getDomain()}`,
          ...getLocaleHeaders()
        },
        data: {}
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('reset_password_success_message');
        }
      })();
      setAlertMessage(message);
      setAlertMessageType('success');
    } catch (err) {
      const message = (() => {
        try {
          return err.response.data['errorMessage'];
        } catch (e) {
          return getLocalString('something_went_wrong');
        }
      })();
      setAlertMessage(message);
      setAlertMessageType('danger');
      setSubmitDisable(false);
    }
  };


  return (
    <Container>
      <div className={classnames(styles.content, 'row')}>
        <Link
          to='/sign-in'
          isUnStyled={true}>
          <img
            src={logo}
            alt={'zinier'}
            className={styles.img}
          />
        </Link>
        <div className={classnames(styles.card, 'col-md-4')}
        >
          <p className={styles['cardTitle']}>
            {getLocalString('reset_password', 'Reset Password')}
          </p>
          <div className={styles['borderTextBottomWrapper']}>
            <div className={styles['borderTextBottom']}/>
          </div>
          {
            isEmpty(alertMessage)
              ?
              null
              :
              <Alert
                className={styles['alert']}
                message={alertMessage}
                type={alertMessageType}
                onClose={
                  (() => {
                    setAlertMessage('' );
                    if(alertMessageType === 'success') {
                      window.location.href = '/';
                    }
                  })
                }
              />
          }
          <p className={styles['cardDesc']}>
            {getLocalString('Forgot_pwd_info')}</p>
          <OrgId
            onChange={ () => true}
            orgId={orgId}
            maxLength= {255}
            errorText = {errorTextOrgId}
          />
          <LoginId
            loginId={loginId}
            onChange={handleLoginChange}
            maxLength= {255}
            errorText = {errorTextLoginId}
          />
          <Submit
            onClick={handleSubmit}
            disable={isEmpty(loginId) || isSubmitDisable}
          />
          <hr className={styles['hr']}/>
          <div className={styles.forgotPassLink}>
            <div className={styles.link} onClick={() => checkAthuOConnection()}>
              {getLocalString('reset_password_navigate_signin')}
            </div>
          </div>
        </div>
        <div className={
          classnames(stylesFromSignIn['redirectOrdId'], 'col-md-4')}>
          <NavLinkV2
            classname={stylesFromSignIn.linkWrapper}
            isUnstyled={true}
            onClick={() => true}>
            <Link
              isUnStyled={true}
              to={ '/'}
              className={stylesFromSignIn.orgidLink}
            >
              {getLocalString('pf_login_into_different_org')}
            </Link></NavLinkV2>
        </div>
        {PrivacyLink()}
      </div>
    </Container>
  );
}

export const ForgotPasswordProxy = validateRoute(ForgotPassword);
