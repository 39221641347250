/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React, { useEffect, useState } from 'react';
import TermsAndPrivacy from './TermsAndPrivacy';
import { Loader } from '../../components/Loader/Loader';
import { prepareLabURL } from '../../utilities/constants';
import { validateActivationStatus } from '../UserActivation-Contained/validateActivationStatus';

function TermsAndPrivacyContainerProxy (props) {
  const [state, setState] = useState({});
  useEffect(() => {
    setState(props.dataFromURL);
  }, [props.dataFromURL]);
  return (
    <div>
      {
        state?.lab || state.env
          ?
          <TermsAndPrivacy
            envId={`${prepareLabURL(state.env, state.lab)}`}
            orgId={state.orgId}
            env = {state.env}
            envLab = {state.lab}
            name={state.name}
            loginId={state.loginId}
            passwordToken={state.passwordToken}
            passwordParam={props.passwordParam}
            passwordConfirmationParam={props.passwordConfirmationParam}
            handleNext={props.handleNext}
            handleSkipSecQuestionStep={props.handleSkipSecQuestionStep}
            setSecurityQuestionsData={props.setSecurityQuestionsData}
          />
          :
          <Loader type='fullScreen'/>
      }
    </div>
  );
}


export const TermsAndPrivacyContainer =
  validateActivationStatus(TermsAndPrivacyContainerProxy);
