//
import * as React from 'react';
import styles from './style.module.css';
import classnames from 'classnames';


export const ReadOnly = (props) => {
  return (
    <div id={props.id || ''} className={classnames(styles['readOnlyText'],
      props.type === 'listView' ? styles['readOnlyList'] : '')}>
      {props.type === 'listView' && props.value
            && Array.isArray(props.value) ?
        props.value.map((item, index) => {
          return (
            <span key={index} className={styles['readOnlyListText']}>
              {item.label}
            </span>
          );
        })
        : props.value}
    </div>
  );

};

