//
import * as React from 'react';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { TextField } from '../../components/TextField';
import classnames from 'classnames';
import styles from './style.module.css';



/**
 * FormField
*/
export class FormField extends React.Component {
  /**
   * handleChange
  */
  handleChange = (e) => {
    this.props.onChange(this.props.stateKey, e.currentTarget.value);
  };

  handleClick = () => {
    this.props.onClick(this.props.stateKey);
  };

  handleFocus = () => {
    this.props.onFocus && this.props.onFocus(this.props.stateKey);
  }

  /**
  * render
  * @return {*}
  */
  render () {
    return (
      <FieldWithLabel field={
        [
          <TextField
            key="inputField"
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            onClick={this.handleClick}
            placeholder={this.props.placeholder}
            value={this.props.value}
            type={this.props.type}
            disabled={this.props.disabled}
            readOnly={this.props.readOnly}
            showPassword={this.props.showPassword}
            showPasswordConfirmation={this.props.showPasswordConfirmation}
            src={this.props.src}
            showEyeIcon={this.props.showEyeIcon}
          />,
          <span key="feedbackMessage">
            {
              this.props.hasFeedback?
                <span className={classnames(styles.feedbackMessage,
                  { [styles.hasSuccess]: this.props.hasSuccess })}>
                  {this.props.message}
                </span> : null
            }
          </span>
        ]
      } label={this.props.label}/>
    );
  }
}
