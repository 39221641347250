import * as React from 'react';
import { useEffect, useState } from 'react';
import { UserActivation } from './UserActivation';
import { Loader } from '../../components/Loader/Loader';
import { prepareLabURL } from '../../utilities/constants';
import { validateActivationStatus } from './validateActivationStatus';


/**
 * UserActivationContainer
 * A container acts as an interface to Application State
 * Since contained components cannot get access to State
 * They rely on their container to pass this information to them
 */
function UserActivationContainerProxy (props) {
  const [state, setState] = useState({});
  useEffect(() => {
    setState(props.dataFromURL);
  }, [props.dataFromURL]);
  return (
    <React.Fragment>
      {
        state !== null && (state.lab || state.env)
          ?
          <UserActivation
            envId={`${prepareLabURL(state.env, state.lab)}`}
            orgId={state.orgIdAlias?state.orgIdAlias : state.orgId}
            env = {state.env}
            envLab = {state.lab}
            name={state.name}
            loginId={state.loginId}
            passwordToken={state.passwordToken}
          />
          :
          <Loader type='fullScreen'/>
      }
    </React.Fragment>
  );
}


export const UserActivationContainer =
  validateActivationStatus(UserActivationContainerProxy);
