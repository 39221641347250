import React from 'react';
import './App.css';

import { Home } from './features/Home/home';

function App () {
  return <Home/>;
}

export default App;
