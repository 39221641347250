/*  */
import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.css';


export const Text = (props) => {
  const {
    children,
    type,
    light,
    style,
    className
  } = props;
  return (
    <div
      className={classnames(className, {
        [styles.default]: type === 'default',
        [styles.heading]: type === 'heading',
        [styles.small]: type === 'small',
        [styles.label]: type === 'label',
        [styles.subHeading]: type === 'subHeading',
        [styles.lightLabel]: type=== 'lightLabel',
        [styles.rightActionLabel]: type === 'rightActionLabel',
        [styles.cardHeading]: type === 'cardHeading',
        [styles.cardSubHeading]: type === 'cardSubHeading',
        [styles.cardText]: type === 'cardText',
        [styles.subHeadingMCL]: type === 'subHeadingMCL',
        [styles.light]: light
      })}
      style={style}
    >
      {children}
    </div>
  );
};

Text.defaultProps = {
  type: 'default',
  style: {}
};