//
import * as React from 'react';
import { Button } from '../../components/Button';
import { getLocalString } from '../../languages';



export class Submit extends React.Component {
  render () {
    return <Button style={{ width: '100%' }}
      onClick={this.props.onClick}
      type={Button.TYPES.primary}
      disabled={this.props.disable}>
      {getLocalString('submit_button')}
    </Button>;
  }
}
