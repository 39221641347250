/* eslint-disable max-len */
/* eslint max-statements: 0 */
import React from 'react';
import { THREE } from '../../utilities/constants';
// import { DropdownList } from '../LanguageDropdown/DropdownMenu';
import styles from '../StepperBuilder/style.module.css';
import logo from '../Logo/zinier-logo-with-name-black.svg';
import StepCompleted from '../assests/StepCompleted.svg';
import { svgList } from '../UserActivation-Contained/ConstantField';

export function Stepper (props) {
  const activeStep = props.activeStep;
  const getSVG = (arg) => {
    if (activeStep === arg) {
      const stepperActiveIcon = svgList.find(icon => icon.step === arg);
      const stepperSource = [stepperActiveIcon].map(icon => icon.activeIcon);
      return <img src={stepperSource} />;
    } else if (arg < activeStep) {
      return <img src={StepCompleted} />;
    }
    const stepperInactiveIcon = svgList.find(icon => icon.step === arg);
    const stepperSource = [stepperInactiveIcon].map(icon => icon.inactiveIcon);
    return <img src={stepperSource} />;
  };

  const getSteps = () => {
    return svgList.map((h, index) => (
      <li key={index} className={h['step'] < activeStep ? styles.completed : styles.notcompleted}>
        {getSVG(h['step'])}{' '}
      </li>));
  };
  return (
    <>
      <div className={styles.centerIcon}>
        <img src={logo} alt={'zinier'} className={styles.img} />
      </div>
      {/* {activeStep > ZERO ? <DropdownList /> : null} */}
      {activeStep <= THREE ? (
        <div className={styles.steps}>
          <ul aria-label={styles.stepsCount}>
            {getSteps()}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export default Stepper;
