/* eslint-disable max-len */
//
import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.module.css';
import { Container } from '../../components/Container/index';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import { Button as ButtonComponent } from '../../components/Button/index';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { TextField } from '../../components/TextField';

import { isEmpty } from 'lodash';
import { AlertControlled } from
  '../../components/AlertControlled/AlertControlled';
import axios from 'axios';

import { NEGATIVE_ONE, prepareLabURL, ZERO } from '../../utilities/constants';
import { getLocalString } from '../../languages/index';
import stylesFromSignIn from '../OrgLanding/styles.module.css';
import { NavLinkV2 } from '../../components/NavLinkV2';
import { Link } from '../../components/Link';
import { PrivacyLink } from '../../components/PrivacyLink';
import { getLocaleHeaders } from '../../utilities/constants';


class ValidateSecurityQuestionsProxy extends Component {

  state = {
    userAnsers: [],
    errors: '',
    envId: this.props.envId,
    envLab: this.props.envLab
  };

  handleSubmit = () => {
    axios({
      url: `${prepareLabURL(this.state.envId, this.state.envLab)}/user/validateSecurityAns`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'orgId': this.props.orgId,
        'loginId': this.props.loginId,
        'token': this.props.passwordToken,
        ...getLocaleHeaders()
      },
      data: { data: this.state.userAnsers }
    }).then(() => {
      if (this.props.onAnswer) {
        this.props.onAnswer();
      } else {
        window.location.href = '/setNewPassWord?orgId='+this.props.orgId+
              '&passwordToken='+this.props.passwordToken+'&loginId='+
            encodeURIComponent(this.props.loginId);
      }
    }).catch(e => {
      const message = e.response.data.errorMessage ?
        getLocalString('Validate_Security_Error_Msg'):'Server Error.';
      this.setState({ errors: message });
    });
  };


  buttonEnable = () => {
    return (this.props.Questions.length ===
        this.state.userAnsers.length) ?
      this.state.userAnsers.map(item => {
        return isEmpty(item.ans);
      }).includes(true): true;
  };

  onchangeAns = (i, e) => {
    const changedState = {
      id: this.props.Questions[i].id,
      ans: e.target.value
    };
    const data = ((onChangedState, stateData) => {
      if ( stateData.length === ZERO) {
        return [onChangedState];
      }
      const index = stateData.map(itr => itr.id).indexOf(onChangedState.id);
      if(index !== NEGATIVE_ONE && stateData[index].id === onChangedState.id) {
        stateData[index].ans = onChangedState.ans;
        return stateData;
      }
      return [...stateData, onChangedState];
    })(changedState, this.state.userAnsers);
    this.setState({ userAnsers: data });
  };

  handleClose = () => {
    this.setState({ errors: '' });
  };

  render () {
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <img
            src={logo}
            alt={'zinier'}
            className={styles.img}
          />
          <div className={classnames(styles.card, 'col-md-4')}>
            <p className={styles.cardTitle}>
              {getLocalString('ANSWER_Security_Questions')}</p>
            <div className={styles.borderTextBottomWrapper}>
              <div className={styles.borderTextBottom}/>
            </div>
            <div className={styles.SecQuesDescription}>
              <p>{getLocalString('ANSWER_Security_Questions_Desc')}</p>
            </div>
            {
              this.state.errors &&
                  <AlertControlled
                    message={this.state.errors}
                    onClose={this.handleClose}
                    type={'danger'}
                    style={{ marginBottom: '1rem' }}
                  />
            }
            {this.props.Questions.map((ques, index) => <div key={index}>
              <FieldWithLabel field={
                <TextField
                  placeholder={getLocalString('Enter_your_answer')}
                  type={'text'}
                  onChange={this.onchangeAns.bind(this, index)}
                />
              }
              className={styles.topBuffer}
              label={ques.question+'?'}/>
            </div>)}
            <div className={styles.topBuffer}>
              <ButtonComponent
                type={'primary'}
                style={{
                  width: '100%'
                }}
                disabled={this.buttonEnable()}
                onClick={this.handleSubmit}
              >
                {getLocalString('continue_button')}
              </ButtonComponent>
            </div>
          </div>
          <div className={
            classnames(stylesFromSignIn['redirectOrdId'], 'col-md-4')}>
            <p>{getLocalString('pf_already_have_account')}
              <NavLinkV2
                classname={stylesFromSignIn.linkWrapper}
                isUnstyled={true}
                onClick={() => true}>
                <Link
                  isUnStyled={false}
                  to={ '/'}
                  className={stylesFromSignIn.orgidLink}
                >
                  {getLocalString('pf_here_link')}
                </Link></NavLinkV2>
            </p>
          </div>
          {PrivacyLink()}
        </div>
      </Container>
    );
  }
}

export const ValidateSecurityQuestions =ValidateSecurityQuestionsProxy;