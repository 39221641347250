
import React from 'react';
import styles from './styles.module.css';



export const Radio = (props) => {
  const { checked, onChange, disabled } = props;
  return (
    <span className={`${styles.radioWrapper} 
      ${checked?'radiobuttonselect':''}` }>
      <input
        className={styles.radio}
        type="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </span>
  );
};