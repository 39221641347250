import { isEmpty, forEach } from 'lodash';
import { FOUR, ONE, prepareLabURL } from '../../utilities/constants';
import { getOrgDetailsForFivePartURL,
  getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';
import { log } from '../../utilities/logger';
import { queryString } from '../../utilities/queryString';

const useUrlParser = async () => {
  const updater = {};
  try {
    const queryStringParsed = queryString.parse(window.location.search);
    forEach(['orgId', 'name',
      'loginId', 'passwordToken', 'env', 'lab'], (x) => {
      updater[x] = queryStringParsed[x];
    });
    if(isEmpty(updater.env) || updater.env === 'undefined') {
      await getEnvDetails(updater);
    } else {
      updater.lab= prepareLabURL(updater.env, updater.lab);
    }
  } catch (err) {
    log(err);
  }
  return updater;
};

const getEnvDetails = async (updater) => {
  const url = window.location.hostname;
  const splitUrl = url.split('.');
  const result = await fetchEnvAndLab(splitUrl, updater.orgId, updater.env);
  if(result && result.data) {
    updater.env = result.data.envName;
    updater.lab = result.data.envUrl;
  }
};
const fetchEnvAndLab = async (splitUrl, orgId, env) => {
  if (splitUrl.length === FOUR) {
    if(isEmpty(env) || env === 'undefined') {
      const result = await
      getOrgDetailsForFivePartURL(orgId, splitUrl[ONE]);
      return result;
    }
    const result = await
    getOrgDetailsForFivePartURL(orgId, env);
    return result;
  }
  const result = await getOrgDetailsForThreePartURL(orgId);
  return result;
};

export default useUrlParser;