//
/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.module.css';
import { Container } from '../../components/Container/index';
import logo from '../Logo/zinier-logo-with-name-black.svg';
import { Button as ButtonComponent } from '../../components/Button/index';

import svg from './UnlockGrey.svg';
import { queryString } from '../../utilities/queryString';
import { forEach } from 'lodash';
import { getLocalString } from '../../languages/index';
import { PrivacyLink } from '../../components/PrivacyLink';


export class MobileAccessPage extends Component {

  state = {
    orgId: '',
    loginId: ''
  };

  componentDidMount () {
    const queryStringParsed = queryString.parse(this.props.location.search);
    const updater = {};
    forEach(['orgId', 'loginId', 'env'], (x) => {
      updater[x] = queryStringParsed[x];
    });
    this.setState(updater);
  }

  handleSubmit = () => {
    window.location.href = '/?org='+ this.state.orgId +
      '&loginId=' + encodeURIComponent(this.state.loginId)+'&env='+this.state.env;
    // localStorage.remove('REDIRECT_TO_AFTER_SESSION_DESTROY');
  };

  render () {
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <img
            src={logo}
            alt={'zinier'}
            style={{
              marginBottom: '2rem'
            }}
          />
          <div className={classnames(styles.card, 'col-md-4')}>
            <p className={styles.cardTitle}>
              {getLocalString('Mobile_Access_Header')}</p>
            <div className={styles.borderTextBottomWrapper}>
              <div className={styles.borderTextBottom}/>
            </div>
            <div className={styles.SecQuesDescription}>
              <p>{getLocalString('Mobile_Access_Desc')}</p>
            </div>
            <div style={{ 'padding': '1rem 7rem' }}>
              <img src={svg} alt='MobileUnlockGreyImage'/>
            </div>
            <div className={styles.topBuffer}>
              <ButtonComponent
                type={'primary'}
                style={{
                  width: '100%'
                }}
                onClick={this.handleSubmit}
              >
                {getLocalString('Continue_To_Login_Screen')}
              </ButtonComponent>
            </div>
          </div>
          {PrivacyLink()}
        </div>
      </Container>
    );
  }
}