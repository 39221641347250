import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.module.css';
import { Container } from '../../components/Container/index';
import logo from '../Logo/zinier-logo-with-name-black.svg';
import svg from './Alert.svg';
import { getLocalString } from '../../languages/index';

export class MobileSignUpPage extends Component {

  render () {
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.contentMobileSignUp, 'row')}>
          <img
            src={logo}
            alt={'zinier'}
            style={{
              marginBottom: '1rem'
            }}
          />
          <div>
            <img src={svg} alt='Alert'/>
          </div>
          <div style={{ 'padding': '1rem', 'width': '38rem' }}>
            <p className={styles.mobileSignUpHeader}>
              {getLocalString('Mobile_SignUp_Header')}</p>
            <div
              className={styles.MobileSignUpDesc}
              style={{ 'padding-top': '1rem', 'text-align': 'center' }}>
              <span>{getLocalString('Mobile_SignUp_Desc1')}</span>
              <b>{getLocalString('Mobile_SignUp_Desc')}</b>
              <span>{getLocalString('Mobile_SignUp_Desc2')}</span>
            </div>
            <div
              style={
                { 'padding-top': '4rem',
                  'width': '38rem', 'text-align': 'center' }
              }>
              <span>{getLocalString('copyright')} 2014 - </span>
              <span>{new Date().getFullYear()}</span>
              <span> Zinier Inc.</span>
              <p>{getLocalString('pf_rights_reserved')}</p>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}