import createAuth0Client from '@auth0/auth0-spa-js';


export const createAuthOConnection = async (options) => {

  return await createAuth0Client({
    domain: options.provider,
    client_id: options.clientId,
    redirect_uri: options.redirectUri,
    scope: 'openid offline_access profile email',
    connection_scope: 'offline_access'
  });
};