/* eslint-disable max-len */
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { APP_NAME_USER, getDomain, isDev, isDevelopmentURL, prepareLabURL } from '../utilities/constants';

export const TOKEN_COOKIE_KEY = 'ziniertoken';
export const ORGID_COOKIE_KEY = 'zinierorgid';
export const LOGIN_ID_COOKIE_KEY = 'zinierloginid';
export const ENV_COOKIE_KEY = 'zinierenv';
export const ENV_LAB_COOKIE_KEY = 'zinierenvlab';
export const REDIRECT_URL_COOKIE_KEY = 'redirectURL';
export const ORG_AUTH_TYPE = 'zinierauthtype';
export const WEB_LOGOUT_URL = 'zinierweblogouturl';
export const ORG_ALIAS_COOKIE_KEY = 'zinierorgalias';
export const BUSINESS_UNIT_KEY = 'zinier_bu';
export const BUSINESS_UNIT_LIST_KEY = 'zinier_bu_list';

export const TOKEN_DEV_COOKIE_KEY = 'ziniertoken_dev';
export const ORGID_DEV_COOKIE_KEY = 'zinierorgid_dev';
export const LOGIN_ID_DEV_COOKIE_KEY = 'zinierloginid_dev';
export const ENV_DEV_COOKIE_KEY = 'zinierenv_dev';
export const ENV_LAB_DEV_COOKIE_KEY = 'zinierenvlab_dev';
export const REDIRECT_URL_DEV_COOKIE_KEY = 'redirectURL_dev';
export const ORG_AUTH_TYPE_DEV = 'zinierauthtype_dev';
export const WEB_LOGOUT_URL_DEV = 'zinierweblogouturl_dev';
export const ORG_ALIAS_DEV_COOKIE_KEY = 'zinierorgalias_dev';
export const BUSINESS_UNIT_DEV_KEY = 'zinier_bu_dev';
export const BUSINESS_UNIT_LIST_DEV_KEY = 'zinier_bu_list_dev';

export const getOrgIDKey = () => isDev() ? ORGID_DEV_COOKIE_KEY : ORGID_COOKIE_KEY;

export const getLoginIDKey = () => isDev() ? LOGIN_ID_DEV_COOKIE_KEY : LOGIN_ID_COOKIE_KEY;

export const getTokenKey = () => isDev() ? TOKEN_DEV_COOKIE_KEY : TOKEN_COOKIE_KEY;

export const getEnvKey = () => isDev() ? ENV_DEV_COOKIE_KEY : ENV_COOKIE_KEY;

export const getEnvLabKey = () => isDev() ? ENV_LAB_DEV_COOKIE_KEY : ENV_LAB_COOKIE_KEY;

export const getRedirectURLKey = () => isDev() ? REDIRECT_URL_DEV_COOKIE_KEY : REDIRECT_URL_COOKIE_KEY;

export const getOrgAuthTypeKey = () => isDev() ? ORG_AUTH_TYPE_DEV : ORG_AUTH_TYPE;

export const getWebLogOutUrlKey = () => isDev() ? WEB_LOGOUT_URL_DEV : WEB_LOGOUT_URL;

export const getOrgID = () => isDev() ? Cookies.get(ORGID_DEV_COOKIE_KEY) : Cookies.get(ORGID_COOKIE_KEY);

export const getLoginID = () => isDev() ? Cookies.get(LOGIN_ID_DEV_COOKIE_KEY) : Cookies.get(LOGIN_ID_COOKIE_KEY);

export const getToken = () => isDev() ? Cookies.get(TOKEN_DEV_COOKIE_KEY) : Cookies.get(TOKEN_COOKIE_KEY);

export const getEnv = () => isDev() ? Cookies.get(ENV_DEV_COOKIE_KEY) : Cookies.get(ENV_COOKIE_KEY);

export const getOrgAlias = () => isDev() ? ORG_ALIAS_DEV_COOKIE_KEY : ORG_ALIAS_COOKIE_KEY;
export const getBusinessUnitKey = () => isDev() ? BUSINESS_UNIT_DEV_KEY : BUSINESS_UNIT_KEY;
export const getBusinessUnitListKey = () => isDev() ? BUSINESS_UNIT_LIST_DEV_KEY : BUSINESS_UNIT_LIST_KEY;

const { hostname = '' } = window.location;

export const domain = hostname.substring(hostname.indexOf('.'));

// eslint-disable-next-line max-statements
export const setSessionCookies = (token = '', org = '', loginId='', envName='', envLab='', authType = '', webLogoutUrl='', orgIdAlias = '', businessUnit = '', businessUnitList = []) => {
  Cookies.set(getTokenKey(), token, { domain, secure: true });
  Cookies.set(getOrgIDKey(), org, { domain, secure: true });
  Cookies.set(getLoginIDKey(), loginId, { domain, secure: true });
  Cookies.set(getEnvKey(), envName, { domain, secure: true });
  Cookies.set(getEnvLabKey(), envLab, { domain, secure: true });
  Cookies.set(getOrgAuthTypeKey(), authType, { domain, secure: true });
  Cookies.set(getOrgAlias(), orgIdAlias || org, { domain, secure: true });
  if(!isEmpty(businessUnit)) {
    Cookies.set(getBusinessUnitKey(), businessUnit, { domain, secure: true });
  }
  if(businessUnitList?.length) {
    Cookies.set(getBusinessUnitListKey(), businessUnitList, { domain, secure: true });
  }
  !isEmpty(webLogoutUrl) && Cookies.set(getWebLogOutUrlKey(), webLogoutUrl, { domain, secure: true });
};
export const handleCookies = (data, env, envLab, authTypeResponse) => {
  const envlabUrl = envLab ? envLab : prepareLabURL(env);
  setSessionCookies(data.token, data.orgId, data.loginId, env, envlabUrl, authTypeResponse.authType, data.webLogOutUrl, data.orgIdAlias, data.businessUnit, data.businessUnitList);
};

// eslint-disable-next-line max-statements
export const handleRedirect = (data, state) => {
  const displayedOrgId = state.org_alias || state.org_id || data.orgIdAlias || data.orgId;
  const isThreePartRedirect = ['hawking', 'platform'].includes(state.env_id);
  const threePartUrl = `https://${displayedOrgId}.${getDomain()}`;

  const compileProdUrl = () => {
    if (state.app_name !== APP_NAME_USER) {
      return isThreePartRedirect
        ? `https://${displayedOrgId}.${state.app_name}.${getDomain()}`
        : `https://${displayedOrgId}.${state.app_name}.${state.env_id}.${getDomain()}`;
    }

    return isThreePartRedirect
      ? threePartUrl
      : `https://${displayedOrgId}.app.${state.env_id}.${getDomain()}`;
  };

  const redirectToProd = () => {
    window.location.href = compileProdUrl();
  };

  if(!isEmpty(state.redirect_url)) {
    if (state.redirect_url.includes('.zinier.net') || state.redirect_url.includes('.devzinier.net')) {
      try {
        const url = new URL(state.redirect_url);
        const path = url.pathname;
        window.location.href = `${compileProdUrl()}${path}`;
      } catch (error) {
        redirectToProd();
      }
    }
  } else if(isDevelopmentURL()) {
    window.location.href = isThreePartRedirect
      ? threePartUrl
      : `https://${displayedOrgId}.${state.app_name}.${state.env_id}.${getDomain()}`;
  } else {
    redirectToProd();
  }
};

export const areCookiesAvailable = () => Boolean(getToken()) &&
    Boolean(getOrgID()) &&
    Boolean(getLoginID()) &&
    Boolean(getEnv());
