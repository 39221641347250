/* eslint-disable max-len */
import axios from 'axios';
import { getDomain } from './constants';

export const getOrgDetails = async (orgId, orgEnv) => {
  const url = orgEnv
    ? `https://loginapp.${getDomain()}/development/getorgdetails`
    : `https://loginapp.${getDomain()}/development/getorgdetails`;

  const orgIdParam = orgEnv
    ? `${orgEnv}:${orgId}`
    : orgId;

  let data = {};

  try {
    data = await axios({
      url,
      method: 'POST',
      data: {
        orgId: orgIdParam,
        isOrgIdToAliasMapping: true
      }
    });
  } catch {
    return Promise.reject(new Error());
  }

  return Promise.resolve(data);
};

export const getOrgDetailsForThreePartURL = async (orgId) => {
  let data = {};
  try {
    data = await axios({
      url: `https://loginapp.${getDomain()}/development/getorgdetails`,
      'Content-Type': 'application/json',
      method: 'POST',
      data: {
        orgId: orgId
      }
    });
  } catch {
    return Promise.reject(new Error());
  }

  return Promise.resolve(data);
};

export const getOrgDetailsForFivePartURL = async (orgId, envName ) => {
  let data = {};
  try {
    data = await axios({
      // eslint-disable-next-line max-len
      url: `https://loginapp.${getDomain()}/development/getorgdetails`,
      'Content-Type': 'application/json',
      method: 'POST',
      data: {
        orgId: `${envName}:${orgId}`
      }
    });
  } catch {
    return Promise.reject(new Error());
  }
  return Promise.resolve(data);
};
