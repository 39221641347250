//

/* eslint max-lines: 0 */
/* eslint max-len: 0 */
/* eslint max-statements: 0 */
import * as React from 'react';
import { FormField } from './FormField';
import { TYPES } from '../../utilities/constants';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button';
import { fetchPasswordValidations }
  from '../../utilities/passwordValidationUtils';
import axios from 'axios';
import { isEmpty, forEach } from 'lodash';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import styles from './style.module.css';
import classnames from 'classnames';
import { Tooltip } from '../../components/Tooltip';
import { AlertControlled
} from '../../components/AlertControlled/AlertControlled';
import { queryString } from '../../utilities/queryString';
import { getLocalString } from './../../languages/index';
import svg from './icon-question.svg';
import { hasCrossScript, sanitizeInput } from '../../utilities/sanitizer';

import { decodeHashToParams } from '../../utilities/decode-hash';
import { prepareLabURL, getDomain, getLocaleHeaders } from '../../utilities/constants';
import { PrivacyLink } from '../../components/PrivacyLink';
import { getOrgDetailsForThreePartURL } from '../../utilities/getOrgEnvDetails';



// CreatePasswordWrapper
class ResetPasswordWrapperProxy extends React.Component {

  // @constructor
  constructor (props) {
    super(props);
    this.state = {
      envId: this.props.envId,
      envLab: this.props.envLab,
      orgId: props.orgId? props.orgId:'',
      passwordToken: props.passwordToken?props.passwordToken:'',
      loginId: props.loginId?props.loginId:'',
      openTooltip: false,
      passwordHint: '',
      passwordRegex: '',
      password: '',
      passwordConfirmation: '',
      errorMessage: null,
      successMessage: null,
      form: {
        password: {},
        passwordConfirmation: {}
      },
      validationErrors: {
        password: {},
        passwordConfirmation: {}
      }
    };
  }


  componentDidMount () {

    this.initilizeData();
  }

  initilizeData = async () => {
    this.getStateUpdateByUrl();
    document.addEventListener('keypress', ( (this.handleClickOutside)) );
    await this.getPasswordValidations();
  }

  getStateUpdateByUrl = () => {
    let parsedQueries = queryString.parse(window.location.search);
    const updater = {};
    const { hash } = parsedQueries;

    parsedQueries = hash ? decodeHashToParams(hash) : parsedQueries;

    forEach(['orgId', 'passwordToken', 'loginId'], (x) => {
      if (parsedQueries[x]) {
        // Replace empty spaces that the querystring parser pparses to +
        updater[x] = parsedQueries[x].replace(' ', '+');
      }
    });
    this.setState(updater);
  }

  getPasswordValidations = async () => {
    const data = await fetchPasswordValidations(this.state);
    if(!isEmpty(data)) {
      this.setState({
        passwordHint: data.pwdHint || '',
        passwordRegex: data.pwdRegex || ''
      });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keypress',
      ( (this.handleClickOutside)) );
  }

  /**
   * Start Session if clicked on outside of button,
   * while all the fields are available
   */
  handleClickOutside =(e) => {
    const dataAvailable = !(isEmpty(
      this.state.password && this.state.passwordConfirmation
        && this.state.loginId && this.state.orgId) ||
        (this.state.validationErrors.password.hasError ||
          this.state.validationErrors.passwordConfirmation.hasError));
    if(dataAvailable && e.code === 'Enter') {
      this.checkForCrossScript();
    }
  };

  /**
   * handleStateChange
   */
  handleStateChange = (key, value) => { //eslint-disable-line
    const errors = {
      password: this.state.validationErrors.password,
      passwordConfirmation: this.state.validationErrors.passwordConfirmation
    };
    const validPassword = new RegExp(this.state.passwordRegex);
    if( key === 'password') {
      if (!value) {
        errors.password.hasError = true;
        errors.password.success = false;
        errors.password.message = getLocalString('Required');
      } else if (!validPassword.test(value)) {
        errors.password.hasError = true;
        errors.password.success = false;
        errors.password.message =
            getLocalString('password_doesnt_meet_requirements');
        this.setState({
          openTooltip: true
        });
      } else {
        errors.password.hasError = false;
        errors.password.success = true;
        errors.password.message = getLocalString('password_meet_requirements');
        this.setState({
          openTooltip: false
        });
        if (value !== this.state.passwordConfirmation) {
          errors.passwordConfirmation.hasError = true;
          errors.passwordConfirmation.success = false;
          errors.passwordConfirmation.message =
              getLocalString('pf_password_does_not_match');
        } else {
          errors.passwordConfirmation.hasError = false;
          errors.passwordConfirmation.success = true;
          errors.passwordConfirmation.message =
              getLocalString('password_match');
        }
      }
    } else if( key === 'passwordConfirmation') {
      if (!value ) {
        errors.passwordConfirmation.hasError = true;
        errors.passwordConfirmation.success = false;
        errors.passwordConfirmation.message = getLocalString('Required');
      } else if (this.state.password !== value) {
        errors.passwordConfirmation.hasError = true;
        errors.passwordConfirmation.success = false;
        errors.passwordConfirmation.message =
            getLocalString('pf_password_does_not_match');
      } else {
        errors.passwordConfirmation.hasError = false;
        errors.passwordConfirmation.success = true;
        errors.passwordConfirmation.message = getLocalString('password_match');
      }
    }
    this.setState({
      [key]: value,
      validationErrors: errors
    });
  };

  onFocus = (stateKey) => {
    const form = Object.assign({ ...this.state.form });
    form[stateKey].touched = true;
    this.setState({
      form: form
    });
  };

  onBlur = (stateKey) => {
    const form = Object.assign({ ...this.state.form });
    form[stateKey].dirty = true;
    this.setState({
      form: form
    });
  };

  checkForCrossScript = () => {
    const isPwdCrossScripted = hasCrossScript(this.state.password)
      && hasCrossScript(this.state.passwordConfirmation);
    if(isPwdCrossScripted) {
      this.setState({ errorMessage: getLocalString('something_went_wrong') });
      return;
    }
    this.setState({ errorMessage: null });
    this.handleSubmit();
  }

  // handleSubmit
  handleSubmit = async () => {
    try {
      const signInUrl =
        '/?org='
        +this.state.orgId+'&loginId='+encodeURIComponent(this.state.loginId)+'&env='+this.state.envId;
      const { data: orgData } = await getOrgDetailsForThreePartURL(this.state.orgId, this.state.envId);
      const response = await axios({
        url: `${prepareLabURL(this.state.envId, this.state.envLab)}/user/resetPassword`,
        headers: {
          'Content-Type': 'application/json',
          'orgId': sanitizeInput(orgData.orgId),
          'loginId': sanitizeInput(this.state.loginId),
          'passwordToken': sanitizeInput(this.state.passwordToken),
          'password': this.state.password,
          'passwordConfirmation': this.state.passwordConfirmation,
          'internalURL': `https://${this.state.orgId}.app.${this.state.envId}.${getDomain()}`,
          ...getLocaleHeaders()
        },
        method: 'POST',
        data: {}
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return getLocalString('reset_password_success_message');
        }
      })();
      this.setState({
        successMessage: message
      });
      window.location.href = signInUrl;
      localStorage.remove('REDIRECT_TO_AFTER_SESSION_DESTROY');
    } catch (err) {
      this.setState({
        errorMessage: (() => {
          try {
            return err.response.data.errorMessage;
          } catch (e) {
            return '';
          }
        })()
      });
    }
  };

  getPasswordHintArray = (passwordHint) => {
    const pwdHintsArray = [];
    if(!isEmpty(passwordHint)) {
      const pwdHints = passwordHint.split(',');
      if (!isEmpty(pwdHints)) {
        forEach((pwdHints), (item, i) => {
          pwdHintsArray.push(<li key={i}>{item}</li>);
        });
      }
    }
    return pwdHintsArray;
  }

  /** render @return {*} */
  render () {
    const pwdHintsArray = this.getPasswordHintArray(this.state.passwordHint);
    return (
      <Container className={styles.container}>
        <div className={classnames(styles.content, 'row')}>
          <img src={logo} alt={'zinier'}
            className={styles.img}/>
          <div className={classnames(styles.card, 'col-md-4')}>
            <p className={styles.cardTitle}>
              {getLocalString('new_password_label')}</p>
            <div className={styles.borderTextBottomWrapper}>
              <div className={styles.borderTextBottom}/>
            </div>
            {
              this.state.successMessage
                ?
                <AlertControlled className={styles['alert']}
                  type={'success'}
                  message={this.state.successMessage}
                  onClose={
                    (() => {
                      this.setState({ successMessage: null });
                    })
                  }/>
                :
                null
            }
            {
              this.state.errorMessage
                ?
                <AlertControlled
                  className={styles['alert']}
                  type={'danger'}
                  message={this.state.errorMessage}
                  onClose={
                    (() => {
                      this.setState({ errorMessage: null });
                    })
                  }
                />
                :
                null
            }
            {
              [
                {
                  label: getLocalString('org_name_label'),
                  placeholder: getLocalString('org_name_placeholder'),
                  stateKey: 'orgId',
                  type: TYPES.text,
                  disabled: true,
                  tooltip: false
                },
                {
                  label: getLocalString('email_label'),
                  placeholder: getLocalString('email_placeholder'),
                  stateKey: 'loginId',
                  type: TYPES.EMAIL,
                  disabled: true,
                  tooltip: false
                },
                {
                  label: getLocalString('new_password_label'),
                  placeholder: getLocalString('enter_new_password_placeholder'),
                  stateKey: 'password',
                  type: TYPES.PASSWORD,
                  disabled: false,
                  tooltip: true
                },
                {
                  label: getLocalString('confirm_new_password'),
                  placeholder: getLocalString('enter_new_password_placeholder'),
                  stateKey: 'passwordConfirmation',
                  type: TYPES.PASSWORD,
                  disabled: false,
                  tooltip: false
                }
              ].map((h, index) => <FormField
                onFocus={this.onFocus}
                key={index}
                label={
                  <div className={styles.centerItem}>
                    {h['label']}
                    {h['tooltip'] ?
                      <Tooltip
                        open={this.state.openTooltip}
                        html={
                          <div className={styles.tooltipText}>
                            <ul>
                              {getLocalString('your_pwd_txt')}
                              <br/>
                              {!isEmpty(pwdHintsArray)?
                                pwdHintsArray: this.state.passwordHint}
                            </ul>
                          </div>
                        }
                        position={'top'}>
                        <img
                          src={svg}
                          alt='question'
                        />
                      </Tooltip>
                      :''}
                  </div>
                }
                onBlur={this.onBlur}
                type={h['type']}
                stateKey={h['stateKey']}
                onChange={this.handleStateChange}
                placeholder={h['placeholder']}
                value={this.state[h['stateKey']]}
                hasFeedback={
                  (this.state.validationErrors[h['stateKey']] &&
                  (this.state.validationErrors[h['stateKey']]['hasError'] ||
                  this.state.validationErrors[h['stateKey']]['success']))
                  &&
                  (this.state.form[h['stateKey']] &&
                    this.state.form[h['stateKey']]['dirty'])
                }
                hasSuccess=
                  {this.state.validationErrors[h['stateKey']] &&
                  this.state.validationErrors[h['stateKey']]['success']}
                message={
                  this.state.validationErrors[h['stateKey']] &&
                  this.state.validationErrors[h['stateKey']]['message']
                }
                disabled={h['disabled']}
              />)
            }
            <Button
              className={styles.fullWidth}
              onClick={this.checkForCrossScript}
              type={Button.TYPES.primary}
              disabled={isEmpty(
                this.state.password && this.state.passwordConfirmation
                && this.state.loginId && this.state.orgId
              ) ||
              (
                this.state.validationErrors.password.hasError ||
                this.state.validationErrors.passwordConfirmation.hasError
              )}>
              {getLocalString('submit_button')}
            </Button>
          </div>
          {PrivacyLink()}
        </div>
      </Container>
    );
  }
}

export const ResetPasswordWrapper = ResetPasswordWrapperProxy;
