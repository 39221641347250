/* eslint max-statements: 0 */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import {
  Button as ButtonComponent
} from '../../components/Button';
import { AuthCode } from './code';
import classnames from 'classnames';
import styles from './styles.module.css';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader/Loader';
import logo from './../Logo/zinier-logo-with-name-black.svg';
import axios from 'axios';
import { validateSession } from '../ValidateRoute/validateSession';
import { PrivacyLink } from '../../components/PrivacyLink';

import { AlertControlled }
  from '../../components/AlertControlled/AlertControlled';

import { AuthOptions } from './AuthOptions';

import { AppContextData } from '../AppContext';
import { validateRoute } from './../ValidateRoute/validateRoute';
import { getLocalString } from '../../languages';
import { getLocaleHeaders, prepareLabURL } from '../../utilities/constants';
import { handleCookies, handleRedirect } from './../../handleCookies/index';
import { triggerSecQuesCall } from '../../utilities/securityQuestionAPI';
function TwoFactorAuthentication () {

  const { dispatch, state: ContextData } = useContext(AppContextData);
  const [errorMessage, setErrorMessage] =useState(null);
  const [successMessage, setSuccessMessage] =useState(null);
  const { state: twoFactorData } = useContext(AppContextData);



  const handleClick = async () => { // eslint-disable-line max-statements
    // const redirectURL = Cookies.get(REDIRECT_URL_COOKIE_KEY);
    setErrorMessage(null);
    setSuccessMessage(null);
    if(ContextData.authCode !== '') {
      try {
        dispatch({
          type: 'SET_LOADER',
          payload: true
        });
        const response = await axios({
          url: `${prepareLabURL(ContextData.env_id, ContextData.env_lab)}/session/validateOTP`,
          headers: {
            'Content-Type': 'application/json',
            'orgId': ContextData.session.orgId,
            'loginId': ContextData.session.loginId,
            'token': ContextData.session.token,
            'logLevel': 'error',
            'otp': ContextData.auth_code,
            ...getLocaleHeaders()
          },
          method: 'POST',
          data: {}
        });
        const message = (() => {
          try {
            return response.data['successMessage'];
          } catch (err) {
            return '';
          }
        })();
        setSuccessMessage(message);
        dispatch({
          type: 'SET_LOADER',
          payload: false
        });
        if(ContextData.session.isSecurityQuestionNeedToBeAnswered) {
          await triggerSecQuesCall(ContextData.session, ContextData, false);
        }else{
          handleCookies(ContextData.session, ContextData.env_id, ContextData.env_lab, ContextData.authTypeResponse);
          handleRedirect(ContextData.session, ContextData);
        }
      } catch (err) {
        const errorMessage= (() => {
          try {
            return err.response.data.errorMessage;
          } catch (error) {
            return '';
          }
        })();
        setErrorMessage(errorMessage);
        dispatch({
          type: 'SET_LOADER',
          payload: false
        });
      }
    }
  };

  const handleResendClick = async (otpMode) => {
    try {
      dispatch({
        type: 'SET_LOADER',
        payload: true
      });
      const response = await axios({
        url: `${prepareLabURL(ContextData.env_id, ContextData.env_lab)}/session/resendOTP`,
        headers: {
          'Content-Type': 'application/json',
          'orgId': ContextData.session.orgId,
          'loginId': ContextData.session.loginId,
          'token': ContextData.session.token,
          'logLevel': 'error',
          'otpMode': otpMode,
          ...getLocaleHeaders()
        },
        method: 'POST',
        data: {}
      });
      const message = (() => {
        try {
          return response.data['successMessage'];
        } catch (e) {
          return '';
        }
      })();
      setSuccessMessage(message);
      dispatch({
        type: 'SET_LOADER',
        payload: false
      });
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: response.data
      });
      dispatch({
        type: '2FA_CODE_CHANGE',
        payload: ''
      });
    } catch (err) {
      const errorMessage= (() => {
        try {
          return err.response.data.errorMessage;
        } catch (error) {
          return '';
        }
      })();
      setErrorMessage(errorMessage);

      dispatch({
        type: 'SET_LOADER',
        payload: false
      });
    }
  };

  const changeAuthMethod = () => {
    dispatch({
      type: 'CHANGE_AUTH_MENU',
      payload: false
    });
  };

  return (
    <Container>
      {twoFactorData.two_factor_auth_data.isAuthMenuHidden ?
        <div className={classnames(styles['Wrapper'], 'row')} >
          {twoFactorData.isWorking ?
            <Loader type='fullScreen'/> : null
          }
          <img
            src={logo}
            alt={'zinier'}
            style={{
              marginBottom: '2rem'
            }}
          />
          <div className={
            classnames(styles['Card'], 'col-md-4')}
          >
            {
              successMessage
                ?
                <AlertControlled
                  className={styles['alert']}
                  type={'success'}
                  message={successMessage}
                  onClose={
                    (() => {
                      successMessage( null );
                    })
                  }
                  style={{
                    marginBottom: '1rem'
                  }}/>
                :
                null
            }
            {
              errorMessage
                ?
                <AlertControlled
                  className={styles['alert']}
                  type={'danger'}
                  message={errorMessage}
                  onClose={
                    (() => {
                      setErrorMessage(null);
                    })
                  }
                  style={{
                    marginBottom: '1rem'
                  }}
                />
                :
                null
            }
            <div className={styles['text']}>
              {getLocalString('two_step_verification')}
            </div>
            <div className={styles['borderTextBottomWrapper']}>
              <div className={styles['borderTextBottom']}/>
            </div>
            <div className={styles['authMessageWrapper']}>
              <div>{`${getLocalString('an_string')} ${ContextData.session.otpMode}
                ${getLocalString('with_a_verification_code_was_sent')}`}</div>
              <div>{ContextData.session.otpTarget}</div>
            </div>
            <AuthCode/>
            <ButtonComponent
              type={'primary'}
              onClick={handleClick}
              disabled={ContextData.auth_code === ''}
              style={{
                width: '100%'
              }}
              className={styles['twoStepButton']}
            >
              {getLocalString('pf_done')}
            </ButtonComponent>
            <hr className={styles['hr']}/>
            <div className={styles.forgotPassLink}>
              {getLocalString('dint_receive_the_code')}
              <div className={styles.resendLink}
                onClick=
                  {() => handleResendClick(ContextData.session.otpMode)}>
                {` ${getLocalString('resend')}`}</div>
            </div>
          </div>
          <div className={styles.alternateLogin}
            onClick={changeAuthMethod}>
            {getLocalString('try_another_way_to_login')}
          </div>
          {PrivacyLink()}
        </div> : null }
      {twoFactorData.two_factor_auth_data.isAuthMenuHidden ? null :
        <AuthOptions otpMode={ContextData.session.otpMode}
          onResendClick={handleResendClick}/>}
    </Container>
  );

}

export const TwoFactorAuth =validateSession(validateRoute(TwoFactorAuthentication));

