/*  */
import * as React from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';


export const NavLinkV2 = (props) => {
  const {
    children, isActive, style, isUnstyled,
    onClick, onMouseDown, isSubNavLink, classname, id } = props;
  return (
    <div
      id={id}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={classnames(classname,
        isUnstyled ? '' : styles['container'], {
          [styles['active']]: isActive,
          [styles['SubNav']]: isSubNavLink
        }
      )}
      style={style}
    >
      {children}
    </div>
  );
};
