import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { prepareLabURL } from '../../utilities/constants';
import { validateActivationStatus } from './validateActivationStatus';
import { UserActivationNew } from './UserActivationNew';


/**
 * UserActivationContainer
 * A container acts as an interface to Application State
 * Since contained components cannot get access to State
 * They rely on their container to pass this information to them
 */
function UserActivationContainerProxy (props) {
  const [state, setState] = useState({});
  useEffect(() => {
    setState(props.dataFromURL);
  }, [props.dataFromURL]);
  return (
    <>
      {
        (state?.lab || state.env)
          ?
          <UserActivationNew
            envId={`${prepareLabURL(state.env, state.lab)}`}
            orgId={state.orgId}
            env = {state.env}
            envLab = {state.lab}
            name={state.name}
            loginId={state.loginId}
            passwordToken={state.passwordToken}
            handleNext={props.handleNext}
            setPasswordData={props.setPasswordData}
          />
          :
          <Loader type='fullScreen'/>
      }
    </>
  );
}


export const UserActivationContainerNew =
  validateActivationStatus(UserActivationContainerProxy);
