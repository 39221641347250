//
import * as React from 'react';
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel';
import { TextField } from '../../components/TextField';
import { TYPES } from '../../utilities/constants';
import { getLocalString } from '../../languages';

import styles from './styles.module.css';


export function LoginId (props) {
  const handleChange = (e) => {
    props.onChange({
      loginId: e.currentTarget.value
    });
  };


  return (
    <FieldWithLabel
      field={
        <>
          <TextField
            onChange={handleChange}
            placeholder={getLocalString('enter_email_placeholder')}
            value={props.loginId}
            type={TYPES.EMAIL}
            maxLength = {props.maxLength}
          />
          { props.errorText &&
            <div className={styles.errorText}>
              {props.errorText}
            </div>
          }

        </>
      }
      label={getLocalString('email_label')}
    />
  );

}
