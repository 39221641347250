//
import * as React from 'react';
import json from './data';
import styles from './styles.module.css';
import Lottie from 'react-lottie';




export class Loader extends React.Component {

  render () {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: json,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className={
        this.props.type === 'fullScreen' ? styles.loaderWrapper: ''}>
        <div className={styles.loaderDiv}>
          <Lottie
            options={defaultOptions}
          />
        </div>
      </div>


    );
  }
}
