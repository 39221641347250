/* eslint-disable max-len */
/* eslint max-statements: 0 */
import axios from 'axios';
import {
  activeQuesQuery,
  numofQuesQuery
} from '../features/UserActivation-Contained/ConstantField';
import { isEmpty } from 'lodash';
import { getLocaleHeaders, prepareLabURL, ZERO } from './constants';

const getLabURL = (env_id, env_lab) => prepareLabURL(env_id, env_lab);

export const triggerSecQuesCall = async (
  data,
  envUrl,
  envId,
  envLab,
  isUserActivation,
  handleNext,
  handleSkipSecQuestionStep,
  setSecurityQuestionsData
) => {
  try {
    const envLab = isUserActivation ? envUrl : getLabURL(envId, envLab);
    const response = await axios({
      url: `${envLab}/query/read`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        orgId: data.orgId,
        loginId: data.loginId,
        token: data['token'],
        ...getLocaleHeaders()
      },
      data: { data: numofQuesQuery }
    });
    const isActiveQuestions = await queryActiveQuestions(data, envLab);
    if (
      response &&
      response.data &&
      !isEmpty(response.data.data) &&
      response.data.data[ZERO].configValue > ZERO &&
      isActiveQuestions
    ) {
      setSecurityQuestionsData(data.orgId, data.loginId, data['token'], envId, envLab, response.data.data[ZERO].configValue, isUserActivation);
      handleNext();
    } else {
      handleSkipSecQuestionStep(data.orgId, data.loginId, envId, envLab);
    }
    return true;
  } catch (err) {
    return false;
  }
};

const queryActiveQuestions = async (data, envLab) => {
  try {
    const response = await axios({
      url: `${envLab}/query/read`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        orgId: data.orgId,
        loginId: data.loginId,
        token: data['token'],
        ...getLocaleHeaders()
      },
      data: { data: activeQuesQuery }
    });
    return Boolean(
      response && response.data.data && response.data.data.length > ZERO
    );
  } catch (e) {
    return false;
  }
};