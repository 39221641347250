
import React from 'react';
import styles from './styles.module.css';


export const Checkbox = (props) => {
  const { checked, onChange, disabled } = props;
  return (
    <span className={`${styles['checkboxWrapper']} 
    ${checked?styles['checkboxselect']:''}`}>
      <input
        className={styles['checkBox']}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </span>
  );
};
