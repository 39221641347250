/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable max-statements */
import React, { useState, useEffect, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { createAuthOConnection } from './initiateAuthO';
import { useLoginApi } from '../../apiHooks/apiHooks';
import { AppContextData } from '../AppContext';
export const Auth0ProviderProxy = (authOProps) => {
  const { handleAuthLoginApi } = useLoginApi();
  const { state } = useContext(AppContextData);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function initiateAuthOLogin () {
      const auth0FromHook = await createAuthOConnection(authOProps.authType);
      if ( authOProps.state && authOProps.state.code && authOProps.state.state) {
        if(auth0FromHook && await authenticateToken(auth0FromHook)) {
          return null;
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        if(await authenticateToken(auth0FromHook)) {
          return null;
        }
      } else {
        await auth0FromHook.loginWithRedirect({ languagekey: authOProps.language || 'en', source: 'web', domain: window.location.host, scope: 'openid offline_access profile email' });
      }
      setLoading(false);
      return null;
    })();
    const authenticateToken = async (auth0FromHook) => {
      const token = await auth0FromHook.getTokenSilently();
      if(token) {
        const idToken = await auth0FromHook.getIdTokenClaims();
        await handleAuthLoginApi(state, idToken);
        return true;
      }
      return null;
    };
  }, []);

  return (
    <div>
      {!isAuthenticated && loading? <Loader type='fullScreen'/> : null}
    </div>
  );
};

export const Auth0Provider = Auth0ProviderProxy;
