//
import axios from 'axios';
import { log } from './logger';
import { FIRST_INDEX, getLocaleHeaders, prepareLabURL } from './constants';

import { isEmpty, pick }
  from 'lodash';

export const fetchPasswordValidations = async (state) => {
  let data = [];
  const headers = pick(state, [
    'orgId'
  ]);
  try {
    const response = await axios({
      url: `${prepareLabURL(state.envId, state.envLab)}/user/getValidations`,
      headers: { ...headers, ...getLocaleHeaders() },
      'Content-Type': 'application/json',
      method: 'POST',
      data: {}
    });
    if(!isEmpty(response.data)&&!isEmpty(response.data.data)) {
      data = response.data.data[FIRST_INDEX];
    }
  } catch (err) {
    try {
      log(err.response.data.errorMessage);
    } catch (err) {
      log('Error in fetchPasswordValidations');
    }
  }
  return data;
};
